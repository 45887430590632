import React, { useState, useContext } from 'react';
import { View, Text, StyleSheet, Platform, Dimensions, Linking, TouchableOpacity, KeyboardAvoidingView, Image } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Checkbox from 'expo-checkbox';
import { TextInput } from 'react-native-paper'; // Import Checkbox component
import CreateAccountButton from '../../components/Buttons/CreateAccountButton/CreateAccountButton';
import EULA from '../../components/Modals/EULA/EULA';
import { Ionicons } from '@expo/vector-icons';
import { UserContext } from '../../context/UserContext';
import HomeScreen from '../home_screen/home_screen';
import AsyncStorage from '@react-native-async-storage/async-storage';


const CreateAccount: React.FC = () => {
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState(''); // Add this line
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showEulaModal, setShowEulaModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { setNavigation } = useContext(UserContext);

  const openPrivacyPolicy = () => {
    const privacyPolicyURL = 'https://www.freeprivacypolicy.com/live/23032729-dac2-4fa8-a965-1ff2e7b456fa';
    Linking.openURL(privacyPolicyURL);
  }

  const goBack = () => {
    window?.history?.back();
    AsyncStorage.clear();
    setNavigation({ screen: 'HomeScreen' });
  }

  return (
    <KeyboardAwareScrollView contentContainerStyle={styles.container}>
      <TouchableOpacity onPress={goBack} style={styles.backButton}>
        <Ionicons name="arrow-back" size={24} color="black" />
      </TouchableOpacity>
      <View style={styles.header}>
        <Text style={styles.headerText}>Create Your Account</Text>
      </View>
      <TextInput
        style={styles.input}
        mode='outlined'
        outlineColor='lightgray'
        placeholder="Email"
        onChangeText={setEmail}
        value={email.toLocaleLowerCase()}
        keyboardType="email-address"
      />
      <TextInput
        style={styles.input}
        mode='outlined'
        outlineColor='lightgray'
        placeholder="Dob (MM/DD/YYYY)"
        onChangeText={setDob}
        value={dob}
        keyboardType="default"
      />

      <TextInput
        style={styles.input}
        mode='outlined'
        outlineColor='lightgray'
        placeholder="Password"
        onChangeText={setPassword}
        value={password}
        secureTextEntry={!showPassword}
        right={
          Platform.OS !== 'web' ?
            <TextInput.Icon icon={showPassword ? 'eye' : 'eye-off'} onPress={() => setShowPassword(!showPassword)} /> :
            <TextInput.Icon
              icon={() => (
                showPassword ? (
                  <Image source={require('./show-icon1.png')} style={styles.hideIcon} />
                ) : (
                  <Image source={require('./hide-icon2.png')} style={styles.hideIcon} />
                )
              )}
              onPress={() => setShowPassword(!showPassword)}
            />
        }
      />
      <TextInput
        style={styles.input}
        mode='outlined'
        outlineColor='lightgray'
        placeholder="Confirm Password"
        onChangeText={setConfirmPassword}
        value={confirmPassword}
        secureTextEntry={!showPassword}
        right={
          Platform.OS !== 'web' ?
            <TextInput.Icon icon={showPassword ? 'eye' : 'eye-off'} onPress={() => setShowPassword(!showPassword)} /> :
            <TextInput.Icon
              icon={() => (
                showPassword ? (
                  <Image source={require('./show-icon1.png')} style={styles.hideIcon} />
                ) : (
                  <Image source={require('./hide-icon2.png')} style={styles.hideIcon} />
                )
              )}
              onPress={() => setShowPassword(!showPassword)}
            />
        }
      />

      {/* Checkbox for "I'm  Single" */}
      <View style={styles.checkboxContainer}>
        <Checkbox
          style={styles.checkBox}
          value={isChecked}
          onValueChange={setIsChecked}
          color={isChecked ? '#CB6CE6' : undefined}
        />
        <Text style={styles.checkBoxText}>I'm single</Text>
      </View>
      <Text style={styles.uelaTextContainer}>
        <Text style={styles.uelaText}>By clicking "Create Account" you agree to our </Text>
        <Text onPress={() => setShowEulaModal(true)} style={styles.linkText}>Terms. </Text>
        <Text style={styles.uelaText}>Learn how we process your data in our </Text>
        <Text onPress={openPrivacyPolicy} style={styles.linkText}>Privacy Policy</Text>
      </Text>
      <CreateAccountButton email={email} password={password} confirm_password={confirmPassword} isSingle={isChecked} dob={dob} />
      <EULA visible={showEulaModal} onClose={() => setShowEulaModal(false)} />
    </KeyboardAwareScrollView>
  );
};

export default CreateAccount;

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  container: {
    height: '100%',
    padding: 20,
    ...Platform.select({
      web: {
        alignItems: 'center',
        paddingTop: windowWidth > 768 ? 0 : 0,
      }
    }),
  },
  backButton: {
    position: 'absolute',
    top: Platform.OS === 'ios' ? 50 : 40,
    left: 20,
    zIndex: 999,
  },
  hideIcon: {
    width: 30,
    height: 30,
  },
  header: {
    marginTop: 100,
    marginBottom: 80,
    ...Platform.select({
      web: {
        alignItems: 'center',
        marginTop: windowWidth > 768 ? 150 : 75,
      },
      android: {
        marginTop: 80,
        marginBottom: 60,
      },
    }),
  },
  headerText: {
    fontSize: 30,
    textAlign: 'center',
  },
  input: {
    width: '100%',
    paddingHorizontal: 10,
    marginBottom: 20,
    ...Platform.select({
      web: {
        paddingVertical: 5,
      },
    }),
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    marginLeft: 10,
    ...Platform.select({
      web: {
        width: windowWidth > 768 ? '31%' : '100%',
        marginLeft: windowWidth > 768 ? 29 : 12,
      },
    }),
  },
  checkBox: {
    borderColor: 'gray',
    marginRight: 10,
  },
  checkBoxText: {
    color: 'black',
    fontSize: 18,
  },
  uelaTextContainer: {
    paddingHorizontal: 10,
    ...Platform
      .select({
        web: {
          width: windowWidth > 768 ? '31%' : '100%',
        }
      }),
  },
  uelaText: {
    color: 'black',
    width: '100%',
  },
  linkText: {
    color: 'blue',
  },
});
