import React, { useContext } from "react";
import { StyleSheet, Pressable, Text } from "react-native";
import { UserContext } from "../../../context/UserContext";

interface Props {
  text?: string;
  screen?: string;
}

const GetStartedButton = ({ text, screen }: Props) => {
  const { setNavigation } = useContext(UserContext);

  const handleButtonPress = () => {
    if (screen) {
      setNavigation({ screen });
    }
  };

  return (
    <Pressable
      style={({ pressed }) => [
        styles.button,
        pressed && styles.buttonPressed,
      ]}
      onPress={handleButtonPress}
    >
      <Text style={styles.buttonText}>{text ? text : "Get Started"}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: 20,
    width: 300,
    borderRadius: 9,
    padding: 17,
    backgroundColor: "#CB6CE6",
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 16,
  },
  buttonPressed: {
    backgroundColor: "#9B4EAB",
  },

});

export default GetStartedButton;
