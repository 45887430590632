import React, { useState, useContext } from "react";
import { View, Text, StyleSheet, Pressable, Platform, Dimensions } from "react-native";
import { createUser } from '../../../controllers/create/create';
import { updateUser } from '../../../controllers/update/update';
import { UserContext } from '../../../context/UserContext';
import { User } from '../../../types/UserContextType';
import { isValidDate } from '../../../utils';


// INTERFACE PROPS TYPE
interface Props {
  email?: string;
  password?: string;
  confirm_password: String;
  isSingle: boolean;
  dob: string;
  user?: User;
}

// CREATE ACCOUNT BUTTON COMPONENT
const CreateAccountButton: React.FC<Props> = ({ email, dob, password, confirm_password, isSingle }) => {
  const [message, setMessage] = useState('');
  const {setNavigation, setIsSingle, users, queryParams } = useContext(UserContext);

  const handleButtonPress = async () => {
    try {
      if (password === confirm_password) {
        if (email && password) {
          setIsSingle(isSingle);
          const uid = await createUser(email, password);
          const header = 'Complete Your Account';

          if (!isValidDate(dob)) {
            return setMessage('dob must be in the format MM/DD/YYYY');
          }
          if (uid.includes('Password should be at least 6 characters')) {
            return setMessage('Password should be at least 6 characters');
          }
          if (uid.includes('Firebase: Error (auth/email-already-in-use)')) {
            return setMessage('Email already in use');
          }
          if (uid) {
            // Navigate to the desired screen after successful login

            if ("ref" in queryParams) {
              const refUid = queryParams.ref;
              updateUser({ uid, dob, displayName:'', photo:'', isSingle, refs:[refUid]}, uid);
              // find the user who invited the new user
              const Inviter = users.find((user) => user.uid === refUid);
              Inviter?.refs.push(uid);
              Inviter && updateUser(Inviter , Inviter.uid);
            }

            if ("pUid" in queryParams) {
              const pUid = queryParams.pUid;
              // update new user
              updateUser({ uid, dob, displayName:'', photo:'', isSingle, pUid:[pUid]}, uid);
              // find the user who invited the new user
              const Inviter = users.find((user) => user.uid === pUid);
              Inviter?.pUid.push(uid);
              Inviter && updateUser(Inviter , Inviter.uid);
            }

            if (!("pUid" in queryParams )&& !("ref" in queryParams)) {
              updateUser({uid, dob, displayName:'', photo:'', isSingle}, uid);
            }
            setNavigation({ screen: 'CompleteAccount', props: { uid, email,} });
          }
        } else {

          setMessage('Email and password are required.');
        }
      } else {
        setMessage('Passwords Do Not Match');
      }
    } catch (error) {
      // Handle login error
      console.log('Create Account Error:', error);
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{message}</Text>
      <Pressable onPress={handleButtonPress} style={styles.pressable}>
        <Text style={styles.buttonText}>{'Create Account'}</Text>
      </Pressable>
    </View>
  );
};

const windowWidth = Dimensions.get('window').width;

// CSS STYLES
const styles = StyleSheet.create({
  container:{
    ...Platform.select({
      web: {
        width: '100%',
        alignItems: 'center',
      },
    }),
  },
  pressable: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: '#CB6CE6',
    borderRadius: 9,
    padding: 15,
    alignItems: 'center',
    justifyContent: 'center',
    ...Platform.select({
      web: {
        width: windowWidth > 768 ? '30.5%' : '100%',
      },
    }),
  },
  buttonText: {
    fontSize: 20,
    color: 'white',
  },
  text: {
    marginTop: 20,
    fontSize: 20,
    color: 'red',
  },
});

export default CreateAccountButton;
