import { getAuth, deleteUser } from "firebase/auth";
import { doc, deleteDoc, setDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject, listAll, list } from "firebase/storage";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { db } from '../firebaseConfig';
import { getUsers } from '../read/read';


// DELETE A USER
import { User } from "firebase/auth";

export const deleteCurrentUser = async (uid: string) => {
  try {
    // CHECK THAT USER IS SIGNED IN
    const auth = getAuth();
    const user = auth.currentUser;
    // Delete user data from Firebase Storage
    const storage = getStorage();
    const storageRef = ref(storage, `users/${uid}`);
    const res = await listAll(storageRef);
    await Promise.all(res.items.map((itemRef) => deleteObject(itemRef)));

    // Delete uid from all users' partner_uids array, refs, and reqVerification array
    const allUsers = await getUsers();

    if (allUsers) {
      await Promise.all(allUsers.map(async (user) => {
        if (user?.pUid?.includes(uid)) {
          const updatedPUids = user?.pUid?.filter((pUid:string) => pUid !== uid);
          const updatedRefs = user?.refs?.filter((ref: string) => ref !== uid);
          const updatedReqVerification = user?.reqVerification?.filter((req:string) => req !== uid);
          const userRef = doc(db, 'users', user.uid);

          await setDoc(userRef, {
            ...user,
            pUid: updatedPUids,
            refs: updatedRefs,
            reqVerification: updatedReqVerification,
          });
        }
      }));
    }


    // Delete user document from Firestore
    const userRef = doc(db, "users", uid);
    await deleteDoc(userRef);

   
    // Delete user from Firebase Authentication
    const deleted = await deleteUser(user as User);

    // Remove current user UID from AsyncStorage
    await AsyncStorage.clear();

    return 'Successfully deleted user, document, and storage data';
  } catch (error: any) {
    console.log('DELETE USER => ERROR:', error.message);
    throw error; // Propagate the error for further handling
  }
};

// DELETE USER PHOTO FROM STORAGE
export const deletePhotos = async (uid: string, isPartner?:boolean) => {
  try {
    const storage = getStorage();
    const storageRef = isPartner ? ref(storage, `users/${uid}/partner`) : ref(storage, `users/${uid}`);
    const res = await listAll(storageRef);
    res.items.forEach(async (itemRef) => await deleteObject(itemRef));
    return 'Successfully deleted photo';
  } catch (error: any) {
    console.log('DELETE PHOTO => ERROR:', error.message);
    throw error;
  }
};