import React, { Children, useEffect, useState, } from "react";
import { StyleSheet,} from "react-native";
import { Button } from 'react-native-paper';
import { updateUser } from '../../../controllers/update/update';
import { UserContext } from '../../../context/UserContext';
import { User } from '../../../types/UserContextType';

// INTERFACE PROPS TYPE
interface ClaimButtonProps {
  partnerUid: string;
  signedInUserUid: string;
  setIsClaimed: React.Dispatch<React.SetStateAction<boolean>>;
}

// CLAIM BUTTON COMPONENT
const ClaimButton: React.FC<ClaimButtonProps> = ({ signedInUserUid, partnerUid, setIsClaimed}) => {
  const { users } = React.useContext(UserContext);
  const partner = users.find((user) => user.uid === partnerUid) as User;

  // ADD UNIQUE ITEM TO ARRAY AND PREVENT DUPLICATES
  const addUniqueItem = (array: any[], item: string) => {
    return array.includes(item) ? array : [...array, item];
  };




  // HANDLE BUTTON PRESS
  const handleButtonPress = () => {
    if (!partner || !partner.reqVerification || !signedInUserUid) {
      // Handle potential null/undefined values or invalid data
      return;
    }
    // PREVENT SIGNED IN USER FROM CLAIMING SAME PARTNER TWICE
    const updatedArray = addUniqueItem(partner.reqVerification, signedInUserUid);

    // SET PARTNER'S REQUEST VERIFICATION ARRAY WITH SIGNED-IN-USER UID
    partner.reqVerification = updatedArray;

    // UPDATE PARTNER'S REQUEST VERIFICATION ARRAY WITH SIGNED-IN-USER UID
    updateUser(partner, partnerUid);

    // SET IS CLAIMED TO TRUE
    setIsClaimed(true);
  };


  return (

    <Button
      style={styles.primaryButton}
      labelStyle={{ fontWeight: '400', letterSpacing: 1 }}
      mode="elevated"
      textColor='white'
      onPress={handleButtonPress}
    >
      Claim Partner
    </Button>
  );
};


// CSS STYLES
const styles = StyleSheet.create({
  primaryButton: {
    backgroundColor: 'blueviolet',
    borderWidth: 0.8,
    borderRadius: 7,
  },
});

export default ClaimButton;