import React, { useState, useContext } from 'react';
import {
  View,
  TextInput,
  Image,
  StyleSheet,
  Text,
  Platform,
  Dimensions,
  ScrollView
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { UserContext } from '../../context/UserContext';
import UpdateButton from '../../components/Buttons/UpdateButton/UpdateButton';
import ClickableUploadText from '../../components/ClickableUploadText/ClickableUploadText';
import BottomNav from '../../components/BottomNav/BottomNav';
import { TextInput as Textinput } from 'react-native-paper';


// UPDATE PROFILE COMPONENT
const UpdateProfile = () => {
  const { signedInUser } = useContext(UserContext);
  const { uid } = signedInUser;
  const [displayName, setName] = useState(signedInUser.displayName);
  const [partners_name, setPartnerName] = useState(signedInUser.partners_name);
  const [relationship_title, setRelationshipStatus] = useState(signedInUser.relationship_title);
  const [start_of_relationship, setRelationshipStartDate] = useState(signedInUser.start_of_relationship);
  const [dob, setDob] = useState(signedInUser.dob);
  const [photo, setPhoto] = useState(signedInUser.photo);
  const [partners_photo, setPartnerPhoto] = useState(signedInUser.partners_photo);


  const updatedUser = {
    ...signedInUser,
    displayName,
    dob,
    partners_name,
    photo,
    partners_photo,
    relationship_title,
    start_of_relationship,
  };

  const hasChanges =
    displayName !== signedInUser.displayName ||
    dob !== signedInUser.dob ||
    partners_name !== signedInUser.partners_name ||
    photo !== signedInUser.photo ||
    partners_photo !== signedInUser.partners_photo ||
    relationship_title !== signedInUser.relationship_title ||
    start_of_relationship !== signedInUser.start_of_relationship;

  return (
    <KeyboardAwareScrollView contentContainerStyle={styles.parentContainer}>
      <ScrollView>
        <View style={styles.subContainer}>
          {/* HEADER */}
          <View style={styles.header}>
            <Text style={styles.headerText}>UPDATE PROFILE</Text>
          </View>
          {/* USER CONTAINER */}
          <View style={styles.usersContainer}>
            {/* USER CARD */}
            <View
              style={signedInUser.isSingle ? styles.userInputContainer2 : styles.userInputContainer}
            >
              <TextInput
                style={styles.userInput}
                placeholder="Your Name"
                value={displayName}
                onChangeText={setName}
              />
              {/* IMAGE CONTAINER */}
              <View
                style={signedInUser.isSingle ? styles.imageContainer2 : styles.imageContainer}>
                <Image
                  style={signedInUser.isSingle ? styles.profilePhoto2 : styles.profilePhoto} source={{ uri: photo }}
                />
              </View>
              <ClickableUploadText setImage={setPhoto} userUid={uid} fileName={!displayName ? 'displayName' : displayName} />
            </View>
            {/* PARTNER CARD */}
            {!signedInUser.isSingle &&
              <View style={styles.userInputContainer}>
                <TextInput
                  style={styles.userInput}
                  placeholder="Partner's Name"
                  value={partners_name}
                  onChangeText={setPartnerName}
                />
                {/* IMAGE CONTAINER */}
                <View style={styles.imageContainer}>
                  <Image style={styles.profilePhoto} source={{ uri: partners_photo }} />
                </View>
                <ClickableUploadText setImage={setPartnerPhoto} userUid={uid} fileName={!partners_name ? 'partners_name' : partners_name} partner={true}/>
              </View>
            }
          </View>

          {/* RELATIONSHIP STATUS INPUT FIELD */}
          {!signedInUser.isSingle &&
            <View style={styles.userDetails}>
              <Text style={styles.labelText}>Relationship Status:</Text>
              <TextInput
                style={styles.userDetailsInput}
                placeholder="Relationship Status"
                value={relationship_title}
                onChangeText={setRelationshipStatus}
              />
            </View>
          }
          {/* RELATIONSHIP START DATE INPUT FIELD */}
          {!signedInUser.isSingle &&
            <View style={styles.userDetails}>
              <Text style={styles.labelText}>Relationship Began:</Text>
              <TextInput
                style={styles.userDetailsInput}
                placeholder="Relationship Start Date"
                value={start_of_relationship}
                onChangeText={setRelationshipStartDate}
              />
            </View>
          }

          <Textinput
            style={styles.input}
            mode='outlined'
            outlineColor='lightgray'
            placeholder="Dob (MM/DD/YYYY)"
            onChangeText={setDob}
            value={dob}
            keyboardType="default"
          />

          {/* UPDATE USER COLLECTION IN FIREBASE */}
          <UpdateButton uid={uid} user={updatedUser} disabled={!hasChanges} />
        </View>
      </ScrollView>
      <BottomNav uid={uid} />
    </KeyboardAwareScrollView>
  );
};

export default UpdateProfile;

// get width of screen
const width = Dimensions.get('window').width;

// CSS STYLES
const styles = StyleSheet.create({
  parentContainer: {
    flex: 1,
    ...Platform.select({
      web: {
        alignItems: 'center',
      },
    }),
  },
  subContainer: {
    flex: 1,
    marginTop: 15,
    paddingHorizontal: 30,
    ...Platform.select({
      web: {
        paddingTop: 0,
        marginTop: 40,
      },
    }),
  },
  input: {
    paddingHorizontal: 10,
    marginBottom: 20,
    ...Platform.select({
      web: {
        paddingVertical: 5,
      },
    }),
  },
  header: {
    marginTop: 60,
    marginBottom: 40,
    ...Platform.select({
      web: {
        marginTop: width > 768 ? 60 : 10,
      },
    }),
  },
  headerText: {
    fontSize: 30,
    textAlign: 'center',
  },
  usersContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  userInputContainer: {
    flexDirection: 'column',
    marginTop: 10,
    borderRadius: 5,
    borderColor: '#ccc',
    borderWidth: 1,
    width: 160,
    padding: 20,
    paddingBottom: 15,
    shadowColor: '#000',
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5, // Required for Android shadow
    zIndex: 1,
    ...Platform.select({
      web: {
        flexWrap: 'wrap',
        alignContent: 'center',
        width: width > 768 ? null : 180,
      },
      android: {
        borderWidth: 0,
      },
    }),
  },
  userInput: {
    textAlign: 'center',
    fontSize: 20,
    paddingBottom: 10,
  },
  imageContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 6,
  },
  profilePhoto: {
    borderRadius: 5,
    height: 100,
    width: 100,
  },
  userInputContainer2: {
    borderRadius: 5,
    borderColor: '#ccc',
    borderWidth: 1,
    padding: 20,
  },

  imageContainer2: {
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 6,
  },
  profilePhoto2: {
    borderRadius: 5,
    height: 200,
    width: '100%',
  },
  userDetails: {
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 20,
  },
  labelText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  userDetailsInput: {
    flexWrap: 'wrap',
    fontSize: 22,
    width: 150,
    paddingLeft: 5,
    ...Platform.select({
      web: {
        flexWrap: 'wrap',
        alignContent: 'center',
        width: width > 768 ? null : 150,
      }
    }),
  },
});
