import React, {useContext} from 'react';
import { TouchableOpacity, Text, StyleSheet, View, Platform } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Octicons } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { UserContext } from '../../context/UserContext';

// INTERFACE FOR NAVLINK
interface NavLinkProps {
  to: string;
  request?: string[];
  uid?: string; // Optional prop for the user id
  iconName?: string; // Optional prop for the icon name
}

// NAVLINK COMPONENT
const NavLink = ({ to, uid, request, iconName }: NavLinkProps) => {
  const {navigation, setNavigation} = useContext(UserContext);
  const handlePress = () => {
    setNavigation({ screen: to});
  };

  const icons: {[key: string]: JSX.Element} = { // Add index signature to allow indexing with a string
    person: <Ionicons name="person" size={25} color="black"/>,
    search: <Octicons name="search" size={24} color="black" />,
    notifications:<Ionicons name="notifications-sharp" size={24} color="black" />,
    settings: <MaterialIcons name="settings" size={24} color="black" />,
  }

  return (
    <TouchableOpacity style={styles.linkContainer} onPress={handlePress} testID={to}>
      {iconName && icons[iconName]}
      { request && request?.length > 0 &&
        <View style={styles.linkContainerText}>
          <Text style={styles.linkText}>{request.length}</Text>
        </View>
      }
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  linkContainer: {
    flexDirection: 'row', // To align icon and text horizontally
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginVertical: 5,
    alignItems: 'center',
  },
  linkText: {
    color: 'white', // black color for the link text
    fontSize: 10,
  },
  linkContainerText: {
    color: 'black', // black color for the link text
    backgroundColor: 'red',
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    height: 15,
    width: 15,
    position: 'absolute',
    left: 35,
    top: 10,
  },
  icon: {
    color: 'black', // black color for the icon
    fontSize: 25,
  },
});

export default NavLink;
