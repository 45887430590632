import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

// INTERFACE FOR UPLOAD PHOTO
interface UploadPhotoProps {
  fileName?: string | undefined;
  uid?: string | undefined;
  uri?: any;
}

// UPLOAD PHOTO TO FIREBASE STORAGE
export const uploadPhoto = async ({ fileName, uid, uri }: UploadPhotoProps) => {
  try {
    // GET THE STORAGE REFERENCE
    const storage = getStorage();

    // GENERATE A REFERENCE TO A LOCATION ASSOCIATED WITH THE USER
    const storageRef = ref(storage, `users/${uid}/${fileName}`);

    // FETCH THE PHOTO DATA FROM THE PROVIDED URI
    const response = await fetch(uri);
    const blob = await response.blob();

    // UPLOAD THE FILE TO THE STORAGE BUCKET
    const uploadTask = await uploadBytesResumable(storageRef, blob);

    //GET DOWNLOAD URL
    const downloadURL = await getDownloadURL(uploadTask.ref);

   // RETURN THE DOWNLOAD URL
   return downloadURL

  } catch (error) {
    console.error('Error fetching users:', error);
  }
};











