import { StyleSheet, Platform, View, Image, ImageBackground, Text, Dimensions, TouchableOpacity, ScrollView } from 'react-native';
import React, { useContext, useEffect, useState, } from 'react';
import { UserContext } from '../../context/UserContext';
import BottomNav from '../../components/BottomNav/BottomNav';
import { MaterialIcons } from '@expo/vector-icons';
import { SignedInUserType } from '../../types/UserContextType';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { User } from '../../types/UserContextType';
import ShareableLinkModal from '../../components/Modals/ShareableLinkModal/ShareableLinkModal';
import Friends from '../../components/Friends/Friends';
import FriendsModal from '../../components/Modals/FriendsModal/FriendsModal';
import { calculateAge } from '../../utils';


interface CurrentUserProfileProps {
  uid: string;
  user?: User;
}
// USER CURRENT PROFILE COMPONENT
const CurrentUserProfile = ({ uid, user }: CurrentUserProfileProps) => {
  const { currentUserID: currentUserUid, users, setSignedInUser, signedInUser, setNavigation, setUserID } = useContext(UserContext);
  const [accordion, setAccordion] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  // GET PARTNER
  const partner = users.find((user) => signedInUser?.pUid?.includes(user.uid));

  // GET FRIENDS
  const friends = users.filter((users) =>
    (signedInUser?.pUid?.includes(users.uid) || signedInUser?.refs?.includes(users.uid)) &&
    (signedInUser?.partners_name !== users.displayName)
  );

  const showFriendsModal = () => {
    setModalVisible(true);
  }

  const hideFriendsModal = () => {
    setModalVisible(false);
  }

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  // GRABS THE USER ID AND NAVIGATES TO THE USER PROFILE SCREEN
  const handleProfileClick = (uid: string) => {
    if (uid) {
      setNavigation({ screen: 'UserProfile' });
      setUserID(uid);
    } else {
      return;
    }
  };

  // Fetch and set the signed-in user when currentUserUid is available
  useEffect(() => {
    if (currentUserUid) {
      async function fetchData() {
        try {
          const foundUser = users?.find((user) => user.uid === currentUserUid);
          if (user && uid === currentUserUid) {
            return setSignedInUser(user as SignedInUserType);
          }
          if (foundUser) {
            return setSignedInUser(foundUser as SignedInUserType);

          }
        } catch (error) {
          console.error('Error getting current user:', error);
        }
      }
      fetchData();
    }
  }, []);


  // If signedInUser is not available, show a loading indicator
  if (!signedInUser) {
    return <LoadingIndicator />;
  }

  const { displayName, dob, partners_name, photo, partners_photo, isSingle } = signedInUser as SignedInUserType
  const image1 = photo ? { uri: photo } : require('./profile-image.png');
  const image2 = partners_photo ? { uri: partners_photo } : require('./profile-image.png');

  return (
    <View style={styles.container}>
      {/* BACKGROUND IMAGE OF USER */}
      <View style={styles.imageWrapper}>
        <ImageBackground
          source={image1}
          style={styles.imageBackground}
        >
          <Text style={styles.userName}>{!isSingle && displayName}</Text>
        </ImageBackground>
      </View>
      {/* VERIFIED ICON/LOGIC */}
      {(signedInUser as SignedInUserType)?.pUid?.length > 0 &&
        <MaterialIcons name="verified" size={50} color="blue" style={styles.verifiedIcon} />
      }
      {/* PARTNER CONTAINER */}
      {
        !isSingle &&
        <TouchableOpacity onPress={() => handleProfileClick(signedInUser?.pUid[0])}>
          <Image source={{ uri: partners_photo }} style={styles.partnerImage} />
        </TouchableOpacity>
      }
      <View style={isSingle ? styles.subContainer2 : styles.subContainer}>
        <ScrollView contentContainerStyle={styles.scrollViewContent}>
          <View style={styles.partnerInfo}>
            <Text style={styles.partnerTitle}>{isSingle ? "Single" : "Partner"}</Text>
            <View style={styles.dobContainer}>
              <Text style={styles.partnerName}>{isSingle ? displayName : partners_name}</Text>
              <Text style={styles.age}>
                {isSingle ? ', ' + calculateAge(dob): calculateAge(partner?.dob) ? ', ' +  calculateAge(partner?.dob) : null}
              </Text>
            </View>
            { friends.length > 0 && <Friends friends={friends} openModal={showFriendsModal}/> }
            {
              !isSingle &&
              <View>
                <Text style={styles.moreInfo}>Relationship Status</Text>
                <Text style={styles.subInfo}>{signedInUser.relationship_title}</Text>
                {accordion && (
                  <View>
                    <Text style={styles.moreInfo}>Dating Since</Text>
                    <Text style={styles.subInfo}>{signedInUser.start_of_relationship}</Text>
                    <Text style={styles.moreInfo}>Age</Text>
                    <Text style={styles.subInfo}>{calculateAge(dob)}</Text>
                  </View>
                )}
                <TouchableOpacity onPress={() => { setAccordion(!accordion) }}>
                  <Text style={styles.readMore}>{accordion ? 'Read less' : 'Read more'}</Text>
                </TouchableOpacity>
              </View>
            }
            <TouchableOpacity onPress={openModal}>
              <View style={styles.invitation}>
                <Text style={styles.invitationText}>{"Invite a friend or partner!"}</Text>
              </View>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
      <BottomNav uid={currentUserUid} request={(signedInUser as SignedInUserType)?.reqVerification}
      />
      <ShareableLinkModal
        visible={isModalVisible}
        onClose={closeModal}
        uid={currentUserUid}
      />
      <FriendsModal
        visible={modalVisible}
        onClose={hideFriendsModal}
        friends={friends}
        currentUser={signedInUser}
      />
    </View>

  );
};

// Get the window's width
const windowWidth = Dimensions.get('window').width;

// CSS STYLES
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: 'white',
    ...Platform.select({
      android: {
        justifyContent: 'flex-start',
      },
    }),
  },
  scrollViewContent: {
    flexGrow: 1,
    paddingBottom: 700,
    ...Platform
      .select({
        web: {
          paddingBottom: 100,
        },
      }),
  },
  subContainer: {
    marginTop: -40,
  },
  subContainer2: {
    marginTop: 40,
  },
  imageWrapper: {
    height: '60%',
    borderBottomRightRadius: 40,
    borderBottomLeftRadius: 40,
    overflow: 'hidden',
    ...Platform.select({
      android: {
        height: '58%',
      },
    }),
  },
  imageBackground: {
    flex: 1,
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 500,
  },
  userName: {
    color: 'white',
    fontSize: 50,
    fontWeight: 'bold',
    position: 'absolute',
    top: 0,
    marginTop: 55,
    marginLeft: 20,
    left: 0,
  },
  dobContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  age :{
    fontSize: 27,
    color: 'gray',
    fontWeight: 'bold',
  },
  verifiedIcon: {
    zIndex: 1,
    marginTop: -65,
    marginLeft: 15,
  },
  partnerImage: {
    marginTop: -40,
    alignSelf: 'flex-end',
    borderWidth: 4,
    borderColor: 'white',
    width: 110,
    height: 110,
    borderRadius: 60,
    marginRight: 30,
  },
  partnerInfo: {
    marginLeft: 45,
  },
  partnerTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#5F45FF',
  },
  partnerName: {
    fontSize: 30,
    fontWeight: 'bold',
    color: 'black',
  },

  moreInfo: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'gray',
    marginTop: 10,
  },
  subInfo: {
    fontSize: 16,
    color: 'black',
    marginTop: 3,

  },
  readMore: {
    fontSize: 15,
    color: '#CB6CE7',
    marginTop: 10,
    ...Platform.select({
      android: {
        marginTop: 5,
      },
    }),
  },
  invitation: {
    marginTop: 15,
    alignItems: 'center',
    width: '90%',
    backgroundColor: 'black',
    padding: 8,
    borderRadius: 3,
    ...Platform.select({
      android: {
        marginTop: 5,
      },
    }),
  },
  invitationText: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold'
  },

});

export default CurrentUserProfile;



