import React, { useState, useEffect, useContext } from 'react';
import { View,StyleSheet, Text, Platform, TouchableOpacity, } from 'react-native';
import UpdateButton from '../../components/Buttons/UpdateButton/UpdateButton';
import CoupleSignupForm from '../../components/Forms/CoupleSignUpForm/CoupleSignupForm';
import SingleSignupForm from '../../components/Forms/SingleSignupForm/SingleSignupForm';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { UserContext } from '../../context/UserContext';
import { isValidDate } from '../../utils';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Ionicons } from '@expo/vector-icons';

interface Props {
  uid: string;
  email: string;
}

const CompleteAccount = ({ uid, email }: Props) => {
  const [displayName, setName] = useState('');
  const [partners_name, setPartnerName] = useState('');
  const [photo, setPhoto] = useState('');
  const [partners_photo, setPartnerPhoto] = useState('');
  const [relationship_title, setRelationshipStatus] = useState('');
  const [start_of_relationship, setRelationshipStartDate] = useState('');
  const [isAllFieldsComplete, setIsAllFieldsComplete] = useState(false);
  const { isSingle, users, signedInUser, setNavigation} = useContext(UserContext);
  const [currentUserID, setCurrentUserID] = useState('' as string);


  // BRAND NEW CREATED USER
  const newUser = users.find((user) => user.uid === uid || user.uid === signedInUser.uid || user.uid === currentUserID);

  // PARTNER OF THE NEW USER IF INVITED
  const partner = newUser && users.find((user) => newUser?.pUid?.includes(user.uid));

  // CREATE DATA OBJECT TO PASS TO UPDATE BUTTON
  const data = {
    uid: uid || newUser?.uid,
    pUid: newUser?.pUid || [],
    email: email || newUser?.email,
    dob: newUser?.dob,
    displayName,
    partners_name,
    photo,
    partners_photo,
    relationship_title,
    start_of_relationship,
    reqVerification: [],
    isSingle: newUser?.isSingle,
    refs: newUser?.refs || [],
  };


useEffect(() => {
  const fetchData = async () => {
    // Get local storage item
    try {
      const userID = await AsyncStorage.getItem('currentUserUid');
      setCurrentUserID(userID as string);
    } catch (error) {
      console.error('Error fetching data from AsyncStorage:', error);
    }
  };

  fetchData(); // Call the fetchData function

  // Update state based on dependencies
  if (isSingle || newUser?.isSingle || signedInUser.isSingle) {
    setIsAllFieldsComplete(!!displayName && !!photo);
  } else {
    setIsAllFieldsComplete(
      !!displayName && !!partners_name && !!photo && !!partners_photo && !!relationship_title && !!start_of_relationship
    );
  }

  // Update state if partner data exists
  if (partner && newUser && newUser.pUid.length) {
    setPartnerName(partner.displayName);
    setPartnerPhoto(partner.photo);
    setRelationshipStatus(partner.relationship_title);
    setRelationshipStartDate(partner.start_of_relationship);
  }
}, [displayName, partners_name, photo, partners_photo, relationship_title, start_of_relationship, data, isSingle, newUser, partner]);

  const image1 = photo ? { uri: photo } : require('./profile-image.png');
  const image2 = partners_photo ? { uri: partners_photo } : require('./profile-image.png');

  const goBack = () => {
    window?.history?.back();
    AsyncStorage.clear();
    setNavigation({ screen: 'HomeScreen' });
  }

  return (
    <KeyboardAwareScrollView contentContainerStyle={styles.container}>
        <TouchableOpacity onPress={goBack} style={styles.backButton}>
        <Ionicons name="arrow-back" size={24} color="black" />
      </TouchableOpacity>
      <View style={styles.header}>
        <Text style={styles.text}>Complete Profile</Text>
      </View>
      {
        newUser?.isSingle || newUser?.pUid?.length  ?
          <SingleSignupForm
            displayName={displayName}
            setName={setName}
            setPhoto={setPhoto}
            image1={image1}
            uid={newUser?.uid || uid || currentUserID }
          />
          :
          <CoupleSignupForm
            displayName={displayName}
            setName={setName}
            partners_name={partners_name}
            setPartnerName={setPartnerName}
            setPhoto={setPhoto}
            setPartnerPhoto={setPartnerPhoto}
            relationship_title={relationship_title}
            setRelationshipStatus={setRelationshipStatus}
            start_of_relationship={start_of_relationship}
            setRelationshipStartDate={setRelationshipStartDate}
            uid={newUser?.uid || uid || currentUserID}
            image1={image1}
            image2={image2}
          />

      }
      <UpdateButton uid={uid || currentUserID } user={data} disabled={!isAllFieldsComplete} />
      <Text style={styles.footNote}> {!isAllFieldsComplete && 'Please fill in all fields'}
      </Text>
      { newUser?.isSingle ? null: !isValidDate(start_of_relationship) &&
        <Text style={styles.footNote}>
          Please provide the date in MM/DD/YYYY format.
        </Text>
      }
      </KeyboardAwareScrollView>
  );
};

export default CompleteAccount;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 30,
    width: '100%',
  },
  header: {
    marginTop: 50,
    marginBottom: 40,
  },
  backButton: {
    position: 'absolute',
    top: Platform.OS === 'ios' ? 50 : 40,
    left: 20,
    zIndex: 999,
  },
  imageContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 6,
  },
  profilePhoto: {
    borderRadius: 5,
    height: 150,
    width: 160,
  },
  input: {
    width: '100%',
    height: 50,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    marginBottom: 20,
  },
  uploadPhotoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 20,
  },
  text: {
    fontSize: 30,
    textAlign: 'center',
  },
  footNote: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
  },
});

