import React, {useState} from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Platform } from 'react-native';
import { User } from '../../types/UserContextType';

// DECLARE INTERFACE PROPS TYPE
interface NotificationItemProps {
  timestamp: string;
  partner: User; // Add the user prop
  updateUserCollection: (user: User) => void; // Update the function signature
  updated: (user: User) => void; // Update the function signature
}

const NotificationItem = ({
  timestamp,
  partner,
  updateUserCollection,
  updated,
}: NotificationItemProps) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleYesClick = () => {
    // SET PARTNER'S "pUid" TO SIGNED IN USER'S UID
    console.log("NotificationItem.tsx | partner::",partner)
    updateUserCollection(partner);
    // HIDE NOTIFICATION ITEM
    setIsVisible(false);
  };

  const handleNoClick = () => {

    // REMOVE PARTNER'S UID FROM SIGNED IN USER'S "reqVerification" ARRAY
    updated(partner);

    // HIDE NOTIFICATION ITEM
    setIsVisible(false);
  };

  return isVisible ? (
    <View style={styles.notificationItem}>
      <View>
        <Text style={styles.notificationTitle}>New Partner Request</Text>
        <Text style={styles.notificationPartner}>{partner?.displayName || "deleted account"}</Text>
        <Text style={styles.notificationTimestamp}>{timestamp}</Text>
      </View>
        <View>
          <Text style={styles.notificationContent}>Is this your partner?</Text>
          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.button} onPress={handleYesClick}>
              <Text style={styles.buttonText}>Yes</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={handleNoClick}>
              <Text style={styles.buttonText}>No</Text>
            </TouchableOpacity>
          </View>
        </View>
    </View>
  ):null;
};
export default NotificationItem;


const styles = StyleSheet.create({
  notificationPartner: {
    fontSize: 16,
    fontWeight: '400',
    marginTop: 5,
    marginBottom: 5,
  },
  notificationItem: {
    backgroundColor: '#f0f0f0',
    padding: 16,
    borderRadius: 8,
    marginTop: 12,
    marginHorizontal: 16,
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  notificationTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  notificationContent: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 10,
  },
  notificationTimestamp: {
    fontSize: 12,
    color: '#888',
  },
  noNotificationsText: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: 100,
    color: '#888',
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'black',
    width: 70,
    height: 35,
    justifyContent: 'center',
    borderRadius: 5,
    marginRight: 10,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
  },
});