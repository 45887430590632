import React, { useState } from 'react';
import { View, Text, StyleSheet, Platform, TextInput } from 'react-native';
import ResetPasswordButton from '../../components/Buttons/ResetButton/ResetPasswordButton';
import SuccessModal from '../../components/Modals/Success_Modal/success_modal';


interface ResetPasswordProps {
  onSendPress: (email: string) => void;
}

const ResetPasswordScreen = () => {
  const [email, setEmail] = useState('');

  const handleSendPress = () => {
    // You can perform validation on the email here if needed
    if (email.length === 0) {
      alert('Please enter your email');
      return;
    } else {
      alert('Email sent');
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.resetText}>
        We will send you an email with a link to reset your password, please enter the email associated with your account below.
      </Text>

      <TextInput
        style={styles.emailInput}
        placeholder="Enter your email..."
        value={email}
        onChangeText={setEmail}
      />
      <ResetPasswordButton />
      {/* success modal */}
      <SuccessModal  icon="email-check" header="CHECK YOUR EMAIL" message="The instructions for password reset has been sent to your email." visible={false} onClose={function (): void {
        throw new Error('Function not implemented.');
      }} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 40,
    marginTop: 100,
    backgroundColor: '#fff',
    ...Platform.select({
      android: {
        marginTop: 50,
      },
    }),
  },
  resetText: {
    fontSize: 16,
    marginBottom: 16,
  },
  emailInput: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
    padding: 12,
    marginBottom: 16,
  },
});

export default ResetPasswordScreen;
