import React from 'react';
import { Button } from 'react-native-paper';
import { StyleSheet } from 'react-native';

// HANDLE BUTTON PRESS
const handleButtonPress = () => null;


const ResetPasswordButton = () => {
  return (
    <Button
     onPress={handleButtonPress} style={styles.button}
     textColor='white'
     >
      {'Send'}
    </Button>
  );
};


// CSS STYLES
const styles = StyleSheet.create({
  button: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: 'black',
    borderRadius: 9,
    padding: 5,
  },
});

export default ResetPasswordButton;
