import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { Button } from 'react-native-paper';

// INTERFACE PROPS TYPE
interface DeleteProfileButtonProps {
 text?: string;
 press?: () => void;
}

// CREATE ACCOUNT BUTTON COMPONENT
const DeleteProfileButton = ({ text, press }:DeleteProfileButtonProps) => {
  return (
      <Button
        style={styles.primaryButton}
        mode="contained"
        onPress={press}
      >
        {text ? text : 'Delete Profile'}
      </Button>
  );
};

// CSS STYLES
const styles = StyleSheet.create({
  primaryButton: {
    marginTop: 20,
    marginBottom: 0,
    backgroundColor: 'black',
    borderRadius: 9,
    padding: 5,
  },
  text: {
    marginTop: 20,
    fontSize: 20,
    color: 'red',
  },
});

export default DeleteProfileButton;


