import React, { useContext, useState } from 'react';
import { View, Text, StyleSheet, Platform, TouchableOpacity, Image, Dimensions } from 'react-native';
import { UserContext } from '../../context/UserContext';
import LogOutButton from '../../components/Buttons/LogOutButton/LogOutButton';
import DeleteProfileButton from '../../components/Buttons/DeleteProfileButton/DeleteProfileButton';
import SuccessModal from '../../components/Modals/Success_Modal/success_modal';
import DeletePartnerModal from '../../components/Modals/Delete_Partner/delete_partner_modal';
import { AntDesign } from '@expo/vector-icons';
import { signOutUser } from '../../controllers/create/create';
import { deleteCurrentUser } from '../../controllers/delete/delete';
import BottomNav from '../../components/BottomNav/BottomNav';
import DeleteAccoutConfirmationModal from '../../components/Modals/DeleteAccount/DeleteAccount';
import { User } from '../../types/UserContextType';
import { updateUser } from '../../controllers/update/update';


// SETTINGS SCREEN
const SettingsScreen = () => {
  const [deletePartnerModalVisible, setDeletePartnerModalVisible] = useState(false);
  const [deleteAccountModalVisible, setDeleteAccountModalVisible] = useState(false);
  const [isModalVisible, setConfirmDeleteAccountModalVisible] = useState(false);
  const showAccountDeleteModal = () => setConfirmDeleteAccountModalVisible(true);
  const hideAccountDeleteModal = () => setConfirmDeleteAccountModalVisible(false);

  const { signedInUser, setSignedInUser,setNavigation } = useContext(UserContext);
  const { displayName, photo, } = signedInUser;
  const image = photo ? { uri: photo } : require('./profile-pics/gray-profile-image.png');


  // CLOSE MODAL
  const closeModal = () => {
    setDeleteAccountModalVisible(false);
    setDeletePartnerModalVisible(false);
    setConfirmDeleteAccountModalVisible(false);
  };

  // SIGN OUT USER
  const handleSignOut = async () => {
    await signOutUser();
    setSignedInUser({ uid: '', displayName: '', email: '', dob:'', pUid: [], partners_name: '', photo:'', partners_photo: '', relationship_title: '', reqVerification: [], start_of_relationship: '', isSingle: signedInUser.isSingle, refs:[] } as User);
    setNavigation({ screen: 'LoginScreen'});
  };


  // DELETE PARTNER
  const handleDeletedPartner = async () => {
    // OPEN PARTNER DELETE MODAL
    setDeletePartnerModalVisible(true);
  };


  // DELETE USER ACCOUNT
  const handleDeleteAccount = async() => {

    // DELETE USER ACCOUNT
    const deletedUser = await deleteCurrentUser(signedInUser.uid);

    // HIDE ACCOUNT DELETE MODAL
    hideAccountDeleteModal();

    // SHOW SUCCESS MODAL
    setDeleteAccountModalVisible(true);

    // NAVIGATE TO LOGIN SCREEN AFTER 10 SECONDS
    setTimeout(() => {
      setNavigation({ screen: 'HomeScreen'});
    }
    , 10000);
  };

  // EDIT PROFILE
  const handleEditProfile = async () => {
   const uid = signedInUser.uid;
   setNavigation({ screen: 'UpdateProfile', props: { uid, email:'' }})
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
      <View style={styles.header}>
        <Image  source={image} style={styles.userPhoto} />
        <Text style={styles.userName}>{displayName}</Text>
      </View>
      <Text style={styles.subHeading}>Account Settings</Text>
      <TouchableOpacity style={styles.card} onPress={handleDeletedPartner}>
        <Text>Delete Partner</Text>
        <AntDesign name="arrowright" size={24} color="gray" />
      </TouchableOpacity>
      <TouchableOpacity style={styles.card} onPress={handleEditProfile}>
        <Text>Edit Profile</Text>
        <AntDesign name="arrowright" size={24} color="gray" />
      </TouchableOpacity>
      <LogOutButton press={handleSignOut} />
      <DeleteProfileButton press={() => showAccountDeleteModal()} />

      {/* DELETE PARTNER MODAL */}
      <DeletePartnerModal
        header="Delete Partner"
        message=""
        visible={deletePartnerModalVisible}
        onClose={closeModal}
       />
      {/* CONFIRM WITH USER ABOUT DELETING ACCOUNT */}
      <DeleteAccoutConfirmationModal
        isVisible={isModalVisible}
        onClose={closeModal}
        onDelete={handleDeleteAccount}
      />

       {/* SUCCESS MODAL */}
       <SuccessModal  icon="check-circle" header="Account Successfully Deleted!" message="" visible={deleteAccountModalVisible} onClose={closeModal} />

      </View>
      <BottomNav uid={signedInUser.uid} request={signedInUser?.reqVerification} />
    </View>
  );
};

// Get the window's width
const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  container: {
  width: '100%',
  backgroundColor: 'white',
    flex: 1,
    ...Platform.select({
      android: {
        marginTop: 0,
      },
      web:{
        // marginTop: -100,
        alignItems: windowWidth > 768 ? 'center' : 'flex-start',
      }
    }),
  },
  subContainer: {
    flex: 1,
    marginTop: 100,
    paddingHorizontal: 40,
    paddingVertical: 40,
    ...Platform.select({
      web:{
        marginTop: 10,
        width: windowWidth > 768 ? '30%' : '100%',
      }
    }),
  },
  header: {
    alignItems: 'center',
    marginBottom: 50,
  },
  userPhoto: {
    width: 60,
    height: 60,
    borderRadius: 50,
    marginBottom: 8,
  },
  userName: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  subHeading: {
    fontSize: 30,
    fontWeight: 'bold',
    marginTop: 24,
    marginBottom: 40,
    ...Platform.select({
      web:{
        fontSize: windowWidth > 768 ? 30 : 20,
        marginTop: windowWidth > 768 ? 24 : -10,
        marginBottom: windowWidth > 768 ? 40 : 20,
      }
    }),
  },
  card: {
    padding: 16,
    borderRadius: 8,
    marginBottom: 8,
    borderWidth: 3,
    borderColor: '#f0f0f0',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: '#f0f0f0',
    padding: 16,
    borderRadius: 8,
    alignItems: 'center',
    marginBottom: 8,
  },
});

export default SettingsScreen;
