import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Modal, TextInput, Alert, StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { deleteCurrentUser } from '../../../controllers/delete/delete';

interface ConfirmationModalProps {
  isVisible: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isVisible,
  onClose,
  onDelete,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userSignedIn, setUserSignedIn] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUserSignedIn(!!user); // Set userSignedIn based on whether user exists
    });

    // Clean up the listener when component unmounts
    return () => unsubscribe();
  }, []);

  const handleSignInAndDelete = async () => {
    try {
      const auth = getAuth();
      const credentials = await signInWithEmailAndPassword(auth, email, password);
      const { user } = credentials;
      if (user) {
        onDelete();
        onClose();
      } else {
        Alert.alert('Authentication Failed', 'Failed to sign in with the provided credentials. Please check your email and password.');
      }
    } catch (error) {
      console.error('Error signing in and deleting account:', error);
      Alert.alert('Invalid Credentials', 'Please check your email and password and try again.');
    }
  };
  return (
    <Modal visible={isVisible} animationType="slide" transparent>
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
              <Text style={styles.modalText}>Please sign in to confirm deletion:</Text>
              <TextInput
                placeholder="Email"
                value={email}
                onChangeText={setEmail}
                style={styles.modalInput}
              />
              <TextInput
                placeholder="Password"
                value={password}
                onChangeText={setPassword}
                secureTextEntry={true}
                style={styles.modalInput}
              />
              <Button
                style={styles.modalButton2}
                onPress={handleSignInAndDelete}
                mode="contained"
              >
                {"Sign In and Delete Account"}
              </Button>
              <Button
                style={styles.modalButton3}
                onPress={onClose}
                mode="contained"
              >
                {"Cancel"}
              </Button>
        </View>
      </View>
    </Modal>
  );
};

export default ConfirmationModal;

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    width: '80%',
  },
  modalText: {
    marginBottom: 20,
    fontSize: 16,
    fontWeight: 'bold',
  },
  modalInput: {
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgray',
    paddingBottom: 5,
  },
  modalButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 10,
  },
  modalButton2: {
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
    backgroundColor: 'red',
  },
  modalButton3: {
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
    backgroundColor: 'black',
  },
  modalButton: {
    padding: 10,
  },
});
