import React from 'react';
import { ScrollView, Text, StyleSheet, View, Modal, TouchableHighlight } from 'react-native';

interface EulaScreenProps {
  visible: boolean;
  onClose: () => void;
}

const EulaScreen: React.FC<EulaScreenProps> = ({ visible, onClose }) => {
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
    >
      <View style={styles.modalContainer}>
        <ScrollView style={styles.contentContainer}>
          <Text style={styles.title}>End User License Agreement (EULA)</Text>
          <Text style={styles.content}>
            This End User License Agreement ("Agreement") is a legal agreement between you ("User") and DZ9 Systems LLC ("Company") governing the use of "The Commitment App" ("App"). By using the App, you agree to be bound by the terms and conditions of this Agreement.

            {'\n\n'}
            1. License Grant:
            Subject to the terms and conditions of this Agreement, DZ9 Systems LLC grants User a limited, non-exclusive, non-transferable license to use "The Commitment App" solely for personal, non-commercial purposes.

            {'\n\n'}
            2. User Responsibilities:
            2.1. Objectionable Content: User agrees not to upload, transmit, or display any content that is objectionable, offensive, or violates any applicable laws or regulations.
            2.2. Compliance: User agrees to comply with all applicable laws, regulations, and third-party agreements while using "The Commitment App."

            {'\n\n'}
            3. Content Moderation:
            3.1. Filtering Mechanism: DZ9 Systems LLC shall implement a filtering mechanism to identify and restrict objectionable content within "The Commitment App."
            3.2. Content Flagging: User shall have the ability to flag objectionable content directly within "The Commitment App" for review and moderation by DZ9 Systems LLC.

            {'\n\n'}
            4. Intellectual Property:
            User acknowledges that "The Commitment App" and its content, including but not limited to text, graphics, logos, and images, are the intellectual property of DZ9 Systems LLC and are protected by copyright and other intellectual property laws.

            {'\n\n'}
            5. Termination:
            This Agreement is effective until terminated by either party. DZ9 Systems LLC reserves the right to terminate this Agreement and revoke User's access to "The Commitment App" if User violates any terms of this Agreement.

            {'\n\n'}
            6. Limitation of Liability:
            In no event shall DZ9 Systems LLC be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to the use of "The Commitment App."

            {'\n\n'}
            7. Governing Law:
            This Agreement shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of law principles.

            {'\n\n'}
            8. Entire Agreement:
            This Agreement constitutes the entire agreement between User and DZ9 Systems LLC concerning the subject matter herein and supersedes all prior or contemporaneous communications and proposals, whether oral or written, between the parties.

            {'\n\n'}
            9. Amendments:
            DZ9 Systems LLC reserves the right to modify, amend, or update this Agreement at any time without prior notice. User's continued use of "The Commitment App" following any such changes constitutes acceptance of the revised Agreement.

            {'\n\n'}
            By using "The Commitment App," User acknowledges that they have read, understood, and agreed to be bound by the terms and conditions of this Agreement.

            {'\n\n'}
            DZ9 Systems LLC
          </Text>
          <TouchableHighlight
            style={styles.closeButton}
            onPress={onClose}
            underlayColor="transparent"
          >
            <Text style={styles.closeButtonText}>Close</Text>
          </TouchableHighlight>
        </ScrollView>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  contentContainer: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    maxHeight: '80%',
    width: '80%',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  content: {
    fontSize: 16,
  },
  closeButton: {
    marginTop: 20,
    alignSelf: 'center',
  },
  closeButtonText: {
    fontSize: 16,
    color: 'blue',
    paddingBottom: 40,
  },
});

export default EulaScreen;
