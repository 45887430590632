import React, { FC, useContext, useEffect } from 'react';
import { View, Text, Image, StyleSheet, Modal, TouchableOpacity, Dimensions, Platform } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { updateUserField, updateCurrentUser, updateUser } from '../../../controllers/update/update';
import { UserContext } from '../../../context/UserContext';

interface User {
  uid: string;
  displayName: string;
  email: string;
  pUid: string[];
  photo: string;
  partners_name: string;
  partners_photo: string;
  relationship_title: string;
  reqVerification: string[];
  start_of_relationship: string;
  isSingle: boolean;
  refs: string[];
};

interface DeletePartnerModalProps {
  visible: boolean;
  onClose: () => void;
  header: string;
  message: string;
}

const DeletePartnerModal = ({ visible, onClose, header, message }:DeletePartnerModalProps) => {
  const { signedInUser, users, setSignedInUser } = useContext(UserContext);
  const handleDeletePartner = (partnerUid:string) => {
    const partnerObj = users?.find((user) => user.uid === partnerUid) as User;
     // DELETE PARTNER INFO FROM USER DOCUMENT
     if(signedInUser?.pUid?.length === 1){
      updateUser({...signedInUser, partners_name: '', partners_photo: '', relationship_title: '', pUid: [], start_of_relationship:'', isSingle:true},signedInUser.uid);
      onClose();
     } else {

      signedInUser.pUid.splice(signedInUser.pUid.indexOf(partnerUid), 1);
      const nextPartner = users?.find((user) => signedInUser.pUid.includes(user.uid));

      if (nextPartner && signedInUser.partners_name !== nextPartner.displayName) {
          updateUser({
              ...signedInUser,
              partners_name: nextPartner.displayName,
              partners_photo: nextPartner.photo,
              relationship_title: nextPartner.relationship_title,
              start_of_relationship: nextPartner.start_of_relationship
          }, signedInUser.uid);
      }  else {
        updateUser(signedInUser, signedInUser.uid);
      }
      onClose();
     }

     // DELETE USER INFO FROM PARTNER DOCUMENT
      if(partnerObj.pUid.length === 1){
       updateUser({...partnerObj, partners_name: '', partners_photo: '', relationship_title: '', pUid: [], start_of_relationship:'', isSingle:true},partnerObj.uid);
        onClose();
      } else {
        partnerObj.pUid.splice(partnerObj.pUid.indexOf(signedInUser.uid), 1);
        const nextPartner = users?.find((user) => partnerObj.pUid.includes(user.uid));
        if (nextPartner && partnerObj.partners_name !== nextPartner.displayName) {
            updateUser({
                ...partnerObj,
                partners_name: nextPartner.displayName,
                partners_photo: nextPartner.photo,
                relationship_title: nextPartner.relationship_title,
                start_of_relationship: nextPartner.start_of_relationship
            }, partnerObj.uid);
        }
        else {
          updateUser(partnerObj, partnerObj.uid);
        }
        onClose();
      }

  }

  return (
    <Modal animationType="fade" transparent visible={visible} onRequestClose={onClose}>
      <View style={styles.modalContainer}>
        <View style={styles.content}>
          <View>
            <TouchableOpacity onPress={onClose} style={styles.closeButton}>
              <MaterialCommunityIcons name="close" size={24} color="" />
            </TouchableOpacity>
            <Text style={styles.header}>{header}</Text>
            <Text style={styles.message}>{message}</Text>
          </View>
          {/* IF PARTNER EXIST SHOW PARTNER. IF NOT, SHOW MESSAGE "No Partner" */}
          { signedInUser?.partners_name ? (
            <View>
              {signedInUser?.pUid?.map((pUid) => {
                const partner = users?.find((user) => user.uid === pUid) as User;
                return (
                  <TouchableOpacity key={partner.uid} onPress={() => handleDeletePartner(partner.uid)}>
                    <View style={styles.partnerContainer}>
                      <Text>{partner?.displayName}</Text>
                      <Text>Delete</Text>
                    </View>
                  </TouchableOpacity>
                )
              }
              )}
            </View>
          ):(
            <Text>No Partner</Text>
          )
          }
        </View>
      </View>
    </Modal>
  );
};

// Get the window's width
const windowWidth = Dimensions.get('window').width;


// CSS STYLES
const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    padding: 40,
    ...Platform.select({
      web:{
       alignItems: windowWidth > 768 ? 'center' : 'center',
      }
    }),
  },

  content: {
    backgroundColor: '#fff',
    padding: 20,
    paddingBottom: 30,
    borderRadius: 10,
    ...Platform.select({
      web:{
       width: windowWidth > 768 ? '30%' : '100%',
      }
    }),
  },
  partnerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: 10,
    borderWidth: 1,
    borderRadius: 8,
    marginBottom: 20,
  },
  header: {
    fontSize: 24,
  },
  message: {
    fontSize: 18,
    marginBottom: 15,
    textAlign: 'center',
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
  closeButtonText: {
    color: '#fff',
    fontSize: 16,
  },
});

export default DeletePartnerModal;
