import { StyleSheet, View, Image, Text, ImageBackground, Platform, Dimensions, TouchableOpacity, ScrollView } from 'react-native';
import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { User } from '../../types/UserContextType';
import ClaimButton from '../../components/Buttons/ClaimButton/ClaimButton';
import BottomNav from '../../components/BottomNav/BottomNav';
import { MaterialIcons } from '@expo/vector-icons';
import FlagContent from '../../components/Modals/FlagContent/FlagContent';
import { Entypo } from '@expo/vector-icons';
import { calculateAge } from '../../utils';
import Friends from '../../components/Friends/Friends';
import FriendsModal from '../../components/Modals/FriendsModal/FriendsModal';


// USER PROFILE COMPONENT
const UserProfile = () => {
  const { signedInUser, users, userID } = useContext(UserContext);
  const uid = userID
  const user = users.find((user) => user.uid === uid);
  const { displayName, dob, partners_name, photo, partners_photo, relationship_title, start_of_relationship } = user as User;
  const [isClaimed, setIsClaimed] = useState(false);
  const [flagModalVisible, setFlagModalVisible] = useState(false);
  const [accordion, setAccordion] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  // GET PARTNER
  const partner = users.find((eaUser) => user?.pUid?.includes(eaUser.uid));
  const { isSingle } = user as User;


  // GET FRIENDS
  const friends = users.filter((users) =>
    (user?.pUid?.includes(users.uid) || user?.refs?.includes(users.uid)) &&
    (user?.partners_name !== users.displayName)
  );


  // HANDLE FLAG
  const handleFlag = () => {
    setFlagModalVisible(true);
  };


  const showFriendsModal = () => {
    setModalVisible(true);
  }

  const hideFriendsModal = () => {
    setModalVisible(false);
  }

// use effect to check if user has claimed the profile
  useEffect(() => {
    user?.reqVerification.includes(signedInUser.uid) || user?.pUid && user?.pUid?.length > 0
      ? setIsClaimed(true) : setIsClaimed(false)
  }
    , [user, isClaimed]);
  return (
    <View style={styles.container}>
      {/* BACKGROUND IMAGE OF USER */}
      <View style={styles.imageWrapper}>
        <ImageBackground
          source={{ uri: photo }}
          style={styles.imageBackground}
        >
          <Text style={styles.userName}>{!isSingle && displayName}</Text>
          <TouchableOpacity style={styles.icon} onPress={handleFlag}>
            <Entypo name="dots-three-vertical" size={24} color="#5F45FF" />
          </TouchableOpacity>
        </ImageBackground>
        <View style={styles.claimButtonContainer}>
          {!isClaimed &&
            <ClaimButton signedInUserUid={signedInUser.uid} partnerUid={uid} setIsClaimed={setIsClaimed} />
          }
        </View>
      </View>
      {/* VERIFIED ICON/LOGIC */}
      {user?.pUid && user?.pUid?.length > 0 &&
        <MaterialIcons name="verified" size={50} color="blue" style={styles.verifiedIcon} />
      }
      {/* PARTNER CONTAINER */}
       {
        !isSingle &&
        <Image source={{ uri: partners_photo }} style={styles.partnerImage} />
       }

      <View style={isSingle ?styles.subContainer2 :styles.subContainer}>
        <ScrollView contentContainerStyle={styles.scrollViewContent}>
          <View style={styles.partnerInfo}>
            <Text style={styles.partnerTitle}>{isSingle ? "Single" : "Partner"}</Text>
            <View style={styles.dobContainer}>
              <Text style={styles.partnerName}>{isSingle ? displayName : partners_name}</Text>
              <Text style={styles.age}>
                {isSingle ? ', ' + calculateAge(dob): calculateAge(partner?.dob) ? ', ' +  calculateAge(partner?.dob) : null}
              </Text>
            </View>
            { friends.length > 0 && <Friends friends={friends} openModal={showFriendsModal}/> }
            {
              !isSingle &&
              <View>
              <Text style={styles.moreInfo}>Relationship Status</Text>
              <Text style={styles.subInfo}>{relationship_title}</Text>
              {
                accordion && (
                  <View>
                    <Text style={styles.moreInfo}>Dating Since</Text>
                    <Text style={styles.subInfo}>{start_of_relationship}</Text>
                    <Text style={styles.moreInfo}>Age</Text>
                    <Text style={styles.subInfo}>{calculateAge(dob)}</Text>
                  </View>
                )
              }
              <TouchableOpacity onPress={() => setAccordion(!accordion)}>
                <Text style={styles.readMore}>
                  {accordion ? 'Read less' : 'Read more'}
                </Text>
              </TouchableOpacity>
            </View>
            }
          </View>
        </ScrollView>
      </View>
      {/* BOTTOM NAVIGATION */}
      <FlagContent uid={uid} name={displayName} visible={flagModalVisible} onClose={() => setFlagModalVisible(false)} />
      <BottomNav uid={signedInUser.uid} />
      <FriendsModal
        visible={modalVisible}
        onClose={hideFriendsModal}
        friends={friends}
        currentUser={user}
      />
    </View>
  );
};

// Get the window's width
const windowWidth = Dimensions.get('window').width;

// CSS STYLES
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: 'white',
    ...Platform.select({
      android: {
        justifyContent: 'flex-start',
      },
    }),
  },
  scrollViewContent: {
    flexGrow: 1,
    paddingBottom: 700,
    ...Platform
      .select({
        web: {
          paddingBottom: 100,
        },
      }),
  },
  subContainer: {
    marginTop: -20,

  },
  subContainer2: {
    marginTop: 40,
  },
  imageWrapper: {
    height: '60%',
    borderBottomRightRadius: 40,
    borderBottomLeftRadius: 40,
    overflow: 'hidden',
  },
  imageBackground: {
    flex: 1,
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 500,
  },
  userName: {
    color: 'white',
    fontSize: 50,
    fontWeight: 'bold',
    position: 'absolute',
    top: 0,
    marginTop: 55,
    marginLeft: 20,
    left: 0,
  },
  dobContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  age :{
    fontSize: 27,
    color: 'gray',
    fontWeight: 'bold',
  },
  verifiedIcon: {
    zIndex: 1,
    marginTop: -65,
    marginLeft: 15,
  },
  claimButtonContainer: {
    position: 'absolute',
    bottom: 0,
    marginBottom: 30,
    paddingLeft: 30,
  },
  partnerImage: {
    marginTop: -40,
    alignSelf: 'flex-end',
    borderWidth: 4,
    borderColor: 'white',
    width: 110,
    height: 110,
    borderRadius: 60,
    marginRight: 30,
  },
  partnerInfo: {
    marginLeft: 45,
  },
  partnerTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#5F45FF',
  },
  partnerName: {
    fontSize: 30,
    fontWeight: 'bold',
    color: 'White',
  },
  icon: {
    position: 'absolute',
    right: 10,
    bottom: 60,
  },
  moreInfo: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'gray',
    marginTop: 10,
  },
  subInfo: {
    fontSize: 16,
    color: 'black',
    marginTop: 3,
  },
  readMore: {
    fontSize: 15,
    color: '#CB6CE7',
    marginTop: 10,
  },
});

export default UserProfile;
