import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyC-5cIPtn7wJOs5BQs6kGPu8GiieJcwv1s",
  authDomain: "the-commitment-app.firebaseapp.com",
  projectId: "the-commitment-app",
  storageBucket: "the-commitment-app.appspot.com",
  messagingSenderId: "726585865772",
  appId: "1:726585865772:web:ba7ad9dc1475ab25228389",
  measurementId: "G-VZ1K89TXHL"
};

const app = initializeApp(firebaseConfig);
// For more information on how to access Firebase in your project,
// see the Firebase documentation: https://firebase.google.com/docs/web/setup#access-firebase

export const db = getFirestore(app);
