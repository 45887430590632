import React, { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { uploadPhoto } from '../../controllers/upload_photo/upload_photo';
import { deletePhotos } from '../../controllers/delete/delete';


interface ImageUploadButtonProps {
  text?: string;
  setImage?: (uri: any) => void;
  userUid?: string;
  fileName?: string;
  partner?: boolean;
}

const formatUsername = (username: string) => {
  return username.trim().toLowerCase().replace(/\s+/g, '-');
}

const ClickableUploadText = ({ text, setImage, userUid, fileName, partner }:ImageUploadButtonProps) => {
  const pickImage = async () => {
    // ALLOWS USER TO SELECT AN IMAGE FROM THEIR DEVICE PHOTO LIBRARY
    let pickerResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

   // CHECKS IF THE IMAGE SELECTION PROCESS WAS SUCCESSFUL AND NOT CANCELED.
    if (!pickerResult.canceled && pickerResult.assets.length > 0) {

        // EXTRACT THE FILENAME FROM THE LOCAL URI
        const localUri = pickerResult.assets[0].uri;
        fileName = formatUsername(fileName as string);

        if (partner) {
          // DELETE PREVIOUS IMAGE IF IT EXISTS
          deletePhotos(userUid as string, true);
          fileName = `partner/${fileName}`;
        } else {
           // DELETE PREVIOUS IMAGE IF IT EXISTS
          deletePhotos(userUid as string,false);
        }

        // UPLOAD THE IMAGE TO FIREBASE STORAGE
        const downloadURL = await uploadPhoto({ fileName, uid: userUid, uri: localUri });
        try {
          // FETCH THE SELECTED IMAGE USING ITS LOCAL URI
          const { url } = await fetch(localUri);
          // SET THE IMAGE URI TO THE LOCAL URI
          setImage && setImage(downloadURL);

        } catch (error) {
          // HANDLE ANY ERRORS THAT MAY OCCUR DURING THE CONVERSION AND SAVING PROCESS
          console.error('Error converting image to Blob:', error);
        }
    }
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={pickImage}>
        <Text style={styles.text}>{text ? text : 'Upload photo'}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 16,
    color: 'blue',
  },
});

export default ClickableUploadText;
