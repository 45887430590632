import { doc, setDoc } from "firebase/firestore";
import { db } from '../firebaseConfig';
import { getAuth, setPersistence, createUserWithEmailAndPassword,signInWithEmailAndPassword, browserSessionPersistence } from "firebase/auth";
import AsyncStorage from '@react-native-async-storage/async-storage';

// CREATE A USER
export const createUser = async (email:string, password:string) => {
  try {
    const auth = getAuth();
    const userCredential = await createUserWithEmailAndPassword(auth, email, password)
    const newUser = userCredential.user;
    return newUser.uid;
  } catch (error:any) {
    console.log('CREATE USER => ERROR::',error.message);
    return error.message;
  }
};

// SIGN IN A USER
export const signInUser = async (email:string, password:string) => {
  try {
    const auth = getAuth();
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    const signedInUser = userCredential.user;
    await AsyncStorage.setItem('currentUserUid', signedInUser.uid);
    return signedInUser.uid;
  } catch (error:any) {
    console.log('SIGN IN USER => ERROR::',error.message);
  }
};

// SIGN OUT A USER
export const signOutUser = async () => {
  try {
    const auth = getAuth();
    const signedOutUser = await auth.signOut();
    await AsyncStorage.removeItem('currentUserUid');
    // SEND USER BACK TO LOING PAGE USING NAVIGATE FUNCTION
    return signedOutUser;
  } catch (error:any) {
    console.log('SIGN OUT USER => ERROR::',error.message);
  }
};






