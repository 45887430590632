import { doc, getDoc, getDocs, collection, DocumentData } from "firebase/firestore";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { db } from '../firebaseConfig';

// GET ALL USERS
export const getUsers = async () => {
  try {
    const usersSnapshot = await getDocs(collection(db, "users"));
    const usersArray = usersSnapshot.docs.map((doc) => doc.data());
    return usersArray;
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};

// GET A USER
export const getUser = async (userId: string) => {
  try {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("Document does not exist")
    }
  } catch (error) {
    console.error('Error getting user:', error);
  }
};


// GET CURRENT SIGNED IN USER
export const getCurrentUser = async () => {
  try {
    const auth = getAuth();
    const currentUser = await auth.currentUser;
    if (currentUser) {
      return currentUser.uid;

    } else {
      console.log('user is not signed in');
    }
  } catch (error) {
    console.log('ERROR::', error);
  }
};







