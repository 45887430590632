import React, { useState, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import CreateAccount from './src/screens/create_account/create_account';
import CompleteAccount from './src/screens/complete_account/complete_account';
import HomeScreen from './src/screens/home_screen/home_screen';
import UserProfile from './src/screens/user_profile/user_profile';
import CurrentUserProfile from './src/screens/current_user_profile/current_user_profile';
import SearchScreen from './src/screens/search/search';
import LoginScreen from './src/screens/login_screen/login_screen';
import SettingsScreen from './src/screens/Settings/settings';
import NotificationsScreen from './src/screens/Notifications/Notifications';
import ResetPasswordScreen from './src/screens/reset_password/reset_password';
import UpdateProfile from './src/screens/update_profile/update_profile';
import { getUsers } from './src/controllers/read/read';
import { UserContext } from './src/context/UserContext';
import { SignedInUserType, User } from './src/types/UserContextType';
import AsyncStorage from '@react-native-async-storage/async-storage';
import LoadingIndicator from './src/components/LoadingIndicator/LoadingIndicator';
import isProfileComplete from './src/utils';
import * as Linking from 'expo-linking';
import { getAuth } from 'firebase/auth';

// App
export default function App(): JSX.Element {
  const [users, setUsers] = useState([]);
  const [signedInUser, setSignedInUser] = useState({} as SignedInUserType);
  const [navigation, setNavigation] = useState({ screen: 'HomeScreen', props: { uid: '', email: '' } } as navigationType);
  const [currentUserID, setCurrentUserID] = useState('' as string);
  const [isLoading, setIsLoading] = useState(true);
  const [userID, setUserID] = useState('' as string);
  const [isSingle, setIsSingle] = useState(false);
  const [url, setUrl] = useState('' as string);
  const [queryParams, setQueryParams] = useState({} as any);
  const [userPassword, setUserPassword] = useState('' as string);
  const [userEmail, setUserEmail] = useState('' as string);

  //HANDLE DEEP LINK
  const handleDeepLink = async () => {
    Linking.addEventListener('url', (event) => {
    });
  }

  // GET URL
  const getInitialUrl = async () => {
    const initialUrl = await Linking.getInitialURL();
    if (initialUrl) {
      setUrl(initialUrl);
    }
  }

  const getQueryParams = async () => {
    const { queryParams } = Linking.parse(url);
    setQueryParams(queryParams as any);
  }

  interface navigationType {
    screen: string;
    props?: {
      uid: string;
      email: string;
      user: any;
    }
  }


  const renderComponent = (navigation: navigationType, currentUserID: string) => {
    const { screen, props: { uid, email, user } = { uid: '', email: '', user: {} } } = navigation;
    const getCurrentUser = users?.find((user: User) => user.uid === currentUserID);
    if (!currentUserID) {
      if (('ref' in queryParams || 'pUid' in queryParams) && screen !== 'CompleteAccount') {
        return <CreateAccount />;
      }
      if (screen === 'HomeScreen') {
        return <HomeScreen />;
      }
      if (screen === 'LoginScreen') {
        return <LoginScreen />;
      }
      if (screen === 'CreateAccount') {
        return <CreateAccount />;
      }
      if (screen === 'CompleteAccount') {
        return <CompleteAccount uid={uid} email={email} />;
      }
    }

    if (!currentUserID) {
      return <LoadingIndicator />;
    }

    if (currentUserID && getCurrentUser && isProfileComplete(getCurrentUser, getCurrentUser?.isSingle)) {
      if (screen === 'UserProfile') {
        return <UserProfile />;
      }
      if (screen === 'SearchScreen') {
        return <SearchScreen />;
      }
      if (screen === 'SettingsScreen') {
        return <SettingsScreen />;
      }
      if (screen === 'NotificationsScreen') {
        return <NotificationsScreen />;
      }
      if (screen === 'ResetPasswordScreen') {
        return <ResetPasswordScreen />;
      }
      if (screen === 'UpdateProfile') {
        return <UpdateProfile />;
      }
      return <CurrentUserProfile uid={uid} user={user} />;

    } else {
      return <CompleteAccount uid={uid} email={email} />;
    }
  }

  // SET FIREBASE USERS TO USER CONTEXT
  useEffect(() => {
    async function fetchData() {
      try {
        const allUsers = await getUsers();
        setUsers(allUsers as []);


        // cleare storage
        // await AsyncStorage.clear();
        const currentUserUid = await AsyncStorage.getItem('currentUserUid');
        setCurrentUserID(currentUserUid as string);
        const UrlListener = Linking.addEventListener('url', handleDeepLink);
        getInitialUrl();
        getQueryParams();

        return () => UrlListener.remove();

      } catch (error) {
        console.error('Error getting user:', error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching data
      }
    }
    fetchData();
  }, [signedInUser, currentUserID, navigation, url]);


  return (
    <UserContext.Provider value={{ users, signedInUser, setSignedInUser, setNavigation, navigation, currentUserID, setCurrentUserID, userID, setUserID, setIsSingle, isSingle, setQueryParams, queryParams, url, setUrl, userPassword, setUserPassword, userEmail, setUserEmail }}>
      <View style={styles.container}>
        {
          isLoading ?
            <LoadingIndicator /> :
            renderComponent(navigation, currentUserID)
        }
      </View>
    </UserContext.Provider>
  );
};

// CSS STYLES
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
