import React, { useState, useContext,useMemo } from 'react';
import BottomNav from '../../components/BottomNav/BottomNav';
import { BlurView } from 'expo-blur';
import {
  StyleSheet,
  View, Image,
  Text,
  TouchableOpacity,
  TextInput,
  SafeAreaView,
  FlatList,
  Platform,
} from 'react-native';
import { UserContext } from '../../context/UserContext';
import { SignedInUserType, User} from '../../types/UserContextType';
import isProfileComplete from '../../utils';



// SEARCH SCREEN COMPONENT
const SearchScreen = () => {
  const { users, signedInUser, setNavigation, userID, setUserID } = useContext(UserContext);
  const [searchText, setSearchText] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([] as User[]);

  // FILTERS OUT THE SIGNED IN USER
  const filtereOutSignedInUser = users.filter((user: User) => {
    return user.uid !== signedInUser.uid;
  });

  // RUN ONCE WHEN THE COMPONENT MOUNTS
  if (filteredUsers.length === 0) setFilteredUsers(filtereOutSignedInUser);

  // GRABS THE USER ID AND NAVIGATES TO THE USER PROFILE SCREEN
  const handleProfileClick = (uid: string) => {
    setNavigation({ screen: 'UserProfile'});
    setUserID(uid);
  };

  // UPDATES THE SEARCH TEXT STATE
  const handleSearchTextChange = (text: string) => {
    setSearchText(text);
    filterUsers(text);
  };

  // FILTERS THE USERS BASED ON THE SEARCH TEXT
  const filterUsers = (text: string) => {
    const filtered = users.filter((user: User) => {
      return (
        user.uid !== signedInUser.uid && // Exclude signedInUser
        isProfileComplete(user as SignedInUserType,user.isSingle) &&
        (user.displayName.toLowerCase().includes(text.toLowerCase()) ||
          user.email.toLowerCase().includes(text.toLowerCase()))
      );
    });
    setFilteredUsers(filtered);
  };


  // USE MEMOIZATION FOR FILTERED USERS
  const filteredUsersMemo = useMemo(() => {
    // FILTERS THE USERS BASED ON THE SEARCH TEXT
    const searchTextLower = searchText.toLowerCase();
    return users.filter((user:User) => {
      return (
        user.uid !== signedInUser.uid &&
        isProfileComplete(user as SignedInUserType, user.isSingle) &&
        (user.displayName.toLowerCase().includes(searchTextLower) ||
         user.email.toLowerCase().includes(searchTextLower))
      );
    });
  }, [users, signedInUser, searchText]);

  return (
    <SafeAreaView style={styles.container}>
      <TextInput
        style={styles.searchInput}
        placeholder="Search..."
        value={searchText}
        onChangeText={handleSearchTextChange}
      />
      {/* SEARCH ITEMS CONTAINER */}
      <View style={styles.container}>
        {filteredUsersMemo.length === 0 ? (
          <Text style={styles.noResultsText}>No Search Results Found</Text>
        ) : (
          <FlatList
            data={filteredUsersMemo}
            renderItem={({ item }) => {
              const image = item.photo ? { uri: item.photo } : require('./profile-pics/profile-image.png');

              return (
                <TouchableOpacity onPress={() => handleProfileClick(item.uid)}>
                  <View key={item.uid} style={styles.profileContainer2}>
                    {/* Render user item content */}
                    <View style={styles.imageContainer}>
                      <Image source={image} style={styles.mainUserImage2} />
                      <View style={styles.profileHeader2}>
                        <BlurView style={styles.blurImage}>
                          <Text style={styles.headerText2}>{item.displayName}</Text>
                          {/* Add other relevant content */}
                        </BlurView>
                      </View>
                    </View>
                  </View>
                </TouchableOpacity>
              );
            }}
            keyExtractor={item => item.uid}
            contentContainerStyle={styles.scrollView}
          />
        )}
      </View>
      <BottomNav uid={signedInUser.uid} request={signedInUser?.reqVerification} />
    </SafeAreaView>
  );
};

// CSS STYLES
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: 'white',
    paddingBottom:50,
    ...Platform.select({
      web: {
        paddingBottom:20,
      },
    }),
  },
  noResultsText: {
    textAlign: 'center',
    marginTop: 20,
  },
  searchInput: {
    height: 40,
    width: '80%',
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    marginTop: 40,
    marginBottom: 20,
    alignSelf: 'center',
  },
  scrollView: {
    flexGrow: 1,
    marginHorizontal: 10,
    paddingBottom: 80,
  },
  profileContainer2: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  profileHeader2: {
    position: 'absolute',
    bottom: 0,
  },
  blurImage: {
    width: 350,
    height: 50,
    borderBottomRightRadius: 25,
    borderBottomLeftRadius: 25,
    overflow: 'hidden',
    justifyContent: 'center',
  },
  headerText2: {
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'white',
  },
  subHeaderText2: {
    fontSize: 14,
    textAlign: 'center',
    color: '#808080',
  },
  imageContainer: {
    flex: 1,
    alignItems: 'center',
  },
  mainUserImage2: {
    width: 350,
    height: 150,
    borderRadius: 25,
    marginTop: 25,
  },
});

export default SearchScreen;
