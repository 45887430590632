import React, { useState, useEffect } from 'react';
import { View, TextInput, Button, Image, StyleSheet, Text } from 'react-native';
import ClickableUploadText from '../../ClickableUploadText/ClickableUploadText';


interface Props {
	displayName: string;
	setName: (value:string) => void
	partners_name: string
	setPartnerName:(value:string) => void
	setPhoto:(value:string) => void
	image1:{uri:string};
	image2:{uri:string};
	setPartnerPhoto:(value:string) => void
	relationship_title:string
	setRelationshipStatus:(value:string) => void
	start_of_relationship:string
	setRelationshipStartDate:(value:string) => void
	uid:string
}

function CoupleSignupForm({ displayName, setName, partners_name, setPartnerName, setPhoto, setPartnerPhoto, relationship_title, setRelationshipStatus, start_of_relationship, setRelationshipStartDate, uid, image1, image2 }:Props) {
	return (
	  <View>
		<TextInput
		  style={styles.input}
		  placeholder="Your Name"
		  value={displayName}
		  onChangeText={setName}
		/>
		<TextInput
		  style={styles.input}
		  placeholder="Partner's Name"
		  value={partners_name}
		  onChangeText={setPartnerName}
		/>
		<View style={styles.uploadPhotoContainer}>
			<View style={styles.imageContainer}>
				<Image style={styles.profilePhoto} source={image1} />
				<ClickableUploadText setImage={setPhoto} userUid={uid} fileName={!displayName ? 'displayName' : displayName} partner={false}/>
			</View>

			<View style={styles.imageContainer}>
				<Image style={styles.profilePhoto} source={image2 } />
				<ClickableUploadText setImage={setPartnerPhoto} userUid={uid} fileName={!partners_name ? 'partners_name' : partners_name} partner={true} />
			</View>
		</View>
		<TextInput
		  style={styles.input}
		  placeholder="Relationship Status"
		  value={relationship_title}
		  onChangeText={setRelationshipStatus}
		/>
		<TextInput
		  style={styles.input}
		  placeholder="Relationship Start Date(01/01/2021)"
		  value={start_of_relationship}
		  onChangeText={setRelationshipStartDate}
		/>
	  </View>
	)
  }

  export default CoupleSignupForm;

  const styles = StyleSheet.create({
	container: {
	  flex: 1,
	  padding: 30,
	  width: '100%',
	},
	header: {
	  marginTop: 50,
	  marginBottom: 70,
	},
	imageContainer: {
	  flexDirection: 'column',
	  justifyContent: 'center',
	  padding: 6,
	},
	profilePhoto: {
	  borderRadius: 5,
	  height: 150,
	  width: 160,
	},
	input: {
	  width: '100%',
	  height: 50,
	  borderColor: '#ccc',
	  borderWidth: 1,
	  borderRadius: 5,
	  paddingHorizontal: 10,
	  marginBottom: 20,
	},
	uploadPhotoContainer: {
	  flexDirection: 'row',
	  justifyContent: 'space-around',
	  marginBottom: 20,
	},
	text: {
	  fontSize: 30,
	  textAlign: 'center',
	},
	footNote: {
	  color: 'red',
	  textAlign: 'center',
	  marginTop: 10,
	},
  });