import { StyleSheet, View, TouchableOpacity, Text, Image, Platform, Dimensions } from 'react-native';
import React, {useContext} from 'react';
import { useNavigation, NavigationProp } from '@react-navigation/native';
// import Logo from '../../Logo/logo';
import GetStartedButton from '../../components/Buttons/GetStartedButton/GetStartedButton';
import { UserContext } from '../../context/UserContext';

const WIDTH = Platform.select({web:350,default:400});
const HEIGHT = Platform.select({web:300,default:400});



// HOME SCREEN COMPONENT
const HomeScreen = () => {
  const {navigation, setNavigation} = useContext(UserContext);
  const handleLoginPress = () => {
    setNavigation({ screen: 'LoginScreen' });
  };

  return (
    <View style={styles.container}>
        <Image
        style={{ height:HEIGHT, width:WIDTH}}
        source={require('./new-commitment-app-logo.png')}
        />
      <GetStartedButton text={'Get Started'} screen={'CreateAccount'} />
      <TouchableOpacity onPress={handleLoginPress}>
        <Text style={styles.loginLink}>Login</Text>
      </TouchableOpacity>
    </View>
  );
};

export default HomeScreen;

// dimensions for web
const width = Dimensions.get('window').width;

// CSS STYLES
const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: '#fff',
    alignItems: 'center',
    paddingTop: 100,
    ...Platform.select({
      android: {
        justifyContent:'flex-start',
        paddingTop: 80,
      },
      web:{
        justifyContent: width > 768 ? 'center' : 'flex-start',
        paddingTop: width > 768 ? 0 : 100,
      }
    }),
  },
  loginLink: {
    marginTop: 20,
    color: '#CB6CE6',
    fontSize: 18,
  },
});
