import React, { useContext, useState } from 'react';
import { View, Text, StyleSheet, Platform, FlatList, TouchableOpacity } from 'react-native';
import { UserContext } from '../../context/UserContext';
import { updateUser } from '../../controllers/update/update';
import NotificationItem from '../../components/NotificationItem/NotificationItem';
import BottomNav from '../../components/BottomNav/BottomNav';
import { User } from '../../types/UserContextType';

// NOTIFICATIONS SCREEN
const NotificationsScreen = () => {
  const [notificationVisible, setNotificationVisible] = useState(true);
  const { users, signedInUser } = useContext(UserContext);
  const { displayName, partners_name, photo, partners_photo } = signedInUser;
  const partner = users?.find((user) => signedInUser?.reqVerification.includes(user.uid)) as User;
  const timestamp = new Date().toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).replace(',', ' @')
  // .replace(/\//g, '.');

  // UPDATE USER COLLECTION
  const updateUserCollection = (partner: User) => {

    // IF PARTNER EXISTS
    if (partner) {


      // IF SIGNED-IN USER IS SINGLE, UPDATE SIGNED-IN USER TO NOT SINGLE
      if (signedInUser.isSingle) {
        signedInUser.isSingle = false;
        signedInUser.partners_name = partner.displayName;
        signedInUser.partners_photo = partner.photo;
        signedInUser.relationship_title = partner.relationship_title || 'Dating';
        signedInUser.start_of_relationship = new Date().toLocaleDateString();
      }

      // UPDATE PARTNER'S "pUid" TO SIGNED-IN USER'S UID
      partner?.pUid?.push(signedInUser.uid);
      partner.isSingle = false;
      partner.partners_name = signedInUser.displayName;
      partner.partners_photo = signedInUser.photo;
      partner.relationship_title = signedInUser.relationship_title || 'Dating';
      partner.start_of_relationship = new Date().toLocaleDateString();
      // SET PARTNER'S "pUid" TO SIGNED IN USER'S UID
      updateUser(partner, partner.uid);
    }


    if (signedInUser && signedInUser.pUid) {
      // Check if partner's UID is not already in the pUid array
      if (!signedInUser?.pUid?.includes(partner.uid)) {
        // If partner's UID is not in the array, push it
        signedInUser.pUid.push(partner.uid);
      }
    }


    // REMOVE PARTNER'S UID FROM SIGNED IN USER'S "reqVerification" ARRAY
    signedInUser.reqVerification.splice(signedInUser.reqVerification.indexOf(partner?.uid), 1);
    // signedInUser.reqVerification = [];

    // SET SIGNED-IN USER'S "reqVerification" ARRAY
    updateUser(signedInUser, signedInUser.uid);

    // HIDE NOTIFICATION ITEM
    setNotificationVisible(false);
  };

  // UPDATE SIGNED-IN USER
  const updated = (partner: User) => {

    // REMOVE PARTNER'S UID FROM SIGNED IN USER'S "reqVerification" ARRAY
    signedInUser.reqVerification.splice(signedInUser.reqVerification.indexOf(partner?.uid), 1);

    // SET SIGNED IN USER'S "reqVerification" ARRAY
    updateUser(signedInUser, signedInUser.uid);

    // HIDE NOTIFICATION ITEM
    setNotificationVisible(false);
  };

  return (
    <View style={styles.container}>
      {
        signedInUser?.reqVerification?.length > 0 ? (
          signedInUser.reqVerification.map((pUid) => {
            const partner = users?.find((user) => user.uid === pUid) as User;
            return (
              <NotificationItem
                key={pUid}
                partner={partner} // Pass the user object
                timestamp={timestamp}
                updateUserCollection={() => updateUserCollection(partner)}
                updated={() => updated(partner)} // Pass the user object
              />
            );
          })
        ) : (
          <Text style={styles.noNotificationsText}>
            No notifications to display
          </Text>
        )
      }

      <BottomNav uid={signedInUser.uid} request={signedInUser?.reqVerification} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    marginTop: 100,
    width: '100%',
    flex: 1,
    backgroundColor: '#fff',
    ...Platform.select({
      android: {
        marginTop: 50,
        paddingTop: 50,
      },
    }),
  },
  notificationPartner: {
    fontSize: 16,
    fontWeight: '400',
    marginTop: 5,
    marginBottom: 5,
  },
  notificationItem: {
    backgroundColor: '#f0f0f0',
    padding: 16,
    borderRadius: 8,
    marginBottom: 8,
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  notificationTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  notificationContent: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 10,
  },
  notificationTimestamp: {
    fontSize: 12,
    color: '#888',
  },
  noNotificationsText: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: 100,
    color: '#888',
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'black',
    width: 70,
    height: 35,
    justifyContent: 'center',
    borderRadius: 5,
    marginRight: 10,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
  },
});

export default NotificationsScreen;
