import { doc, setDoc, updateDoc } from "firebase/firestore";
import { getAuth, sendPasswordResetEmail, updateProfile } from "firebase/auth";
import { db } from '../firebaseConfig';


// UPDATE A USER
export const updateUser = async (data: object, uid: string) => {
  try {
    setDoc(doc(db, "users", uid), data);
  } catch (error) {
    console.error('Error updating user:', error);
  }
};

// UPDATE A SIGNED IN USER
export const updateCurrentUser = async (newUser: object) => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (currentUser) {
      const updatedUser = await updateProfile(currentUser, newUser);
    }
  } catch (error) {
    console.error('Error updating current user:', error);
  }
};

// UPDATE A SPECIFIC FIELD IN A USER DOCUMENT
export const updateUserField = async (userId: string, fieldToUpdate: any, newValue: any) => {
  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { [fieldToUpdate]: newValue });
    console.log(`Field '${fieldToUpdate}' in user document '${userId}' updated successfully!`);
  } catch (error) {
    console.error("Error updating field:", error);
  }
};


// CREATE A NEW FIELD IN A USER DOCUMENT
export const createNewField = async (userId: string, newField: string, value: any) => {
  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { [newField]: value });
    console.log(`New field '${newField}' created in user document '${userId}' successfully!`);
  } catch (error) {
    console.error("Error creating new field:", error);
  }
};

// FORGOT PASSWORD
export const forgotPassword = async (email: string) => {
  try {
    const auth = getAuth();
    const reset = await sendPasswordResetEmail(auth, email);
    return reset;
  } catch (error) {
    console.error('Error sending password reset email:', error);
    return error;
  }
};
