import React from 'react';
import { Modal, View, Text, StyleSheet, Platform } from 'react-native';
import { Button } from 'react-native-paper';

interface FlagContentProps {
  visible: boolean;
  onClose: () => void;
  uid:string;
  name:string;
}

const FlagContent: React.FC<FlagContentProps> = ({ visible, onClose , uid, name}) => {

  const handleFlag = (uid: string, name: string, category: string) => {
     const url = 'https://api.sheetmonkey.io/form/ioYjTbxkkzoiSFkPMDiH4q';
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({uid, name, category}),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    // Close the modal after flagging
    onClose();
  };

  return (
    <Modal
      visible={visible}
      animationType="slide"
      transparent={true}
      onRequestClose={onClose}>
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.flagContent}>Flag Content</Text>
          <View style={styles.modalViewContainer}>
            <Button
              style={[styles.buttonContainer, { backgroundColor: 'red' }]}
              textColor='white'
              onPress={() => handleFlag(uid,name,'Violence')}
            >
              Violence
            </Button>
            <Button
              style={[styles.buttonContainer, { backgroundColor: 'blue' }]}
              textColor='white'
              onPress={() => handleFlag(uid,name,'Nudity')}
            >
              Nudity
            </Button>
            <Button
              style={[styles.buttonContainer, { backgroundColor: 'green' }]}
              textColor='white'
              onPress={() => handleFlag(uid,name,'Hate Speech')}
            >
              Hate Speech
            </Button>
            <Button
              style={[styles.buttonContainer, { backgroundColor: 'orange' }]}
              textColor='white'
              onPress={() => handleFlag(uid,name,'False Information')}
            >
              False Information
            </Button>
            <Button
              style={[styles.buttonContainer, { backgroundColor: 'gray' }]}
              textColor='white'
              onPress={() => handleFlag(uid,name,'Other')}
            >
              Other
            </Button>
          </View>
          <Button style={styles.buttonContainer}
            textColor='black'
            onPress={onClose}
          >
            Close
          </Button>
        </View>
      </View>
    </Modal>
  );
};


const styles = StyleSheet.create({
  flagContent: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingBottom: 30,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    flexDirection: 'row', // Set flexDirection to 'row' for a two-column layout
    flexWrap: 'wrap', // Allow items to wrap to the next row if needed
    justifyContent: 'center',
    width: '90%',

  },
  modalViewContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    ...Platform.select({
      web: {
        width: '100%',
        justifyContent: 'space-between',
      }
    }),

  },
  buttonContainer: {
    marginVertical: 5,
    width: '48%',
    marginBottom: 10,
  },
});


export default FlagContent;
