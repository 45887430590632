import React, { useState, useContext, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, Dimensions, Platform} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { TextInput } from 'react-native-paper';
import LoginButton from '../../components/Buttons/LoginButton/LoginButton';
import { forgotPassword } from '../../controllers/update/update';
import ForgetPasswordModal from '../../components/Modals/ForgetPasswordModal/ForgetPasswordModal';
import { UserContext } from '../../context/UserContext';
import { User } from '../../types/UserContextType';

// LOGIN SCREEN COMPONENT
const LoginScreen: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { navigation, setNavigation, setSignedInUser } = useContext(UserContext);

  // HANDLE CREATE ACCOUNT
  const handleCreateAccount = () => {
    setNavigation({ screen: 'CreateAccount' });
  };

  useEffect(() => {
    setSignedInUser({} as User)
  }
    , [])

  // HANDLE FORGET PASSWORD
  const forgetPassword = (email: string) => {
    if (email === '') {
      alert('Please enter your email address.');
      setModalVisible(false);
    } else {
      setModalVisible(true);
      forgotPassword(email);
    }
  };

  return (
    <KeyboardAwareScrollView contentContainerStyle={styles.container}>
      <View style={styles.header}>
        <Image source={require('./new-commitment-app-logo.png')} style={styles.logo} />
      </View>
      <View style={styles.content}>
        <TextInput
          style={styles.input}
          mode="outlined"
          outlineColor='lightgray'
          placeholder="Email"
          value={email}
          onChangeText={(text) => setEmail(text.toLowerCase())}
        />
        <View style={styles.inputContainer}>
          <TextInput
            style={styles.input}
            mode="outlined"
            outlineColor='lightgray'
            placeholder="Password"
            secureTextEntry={!showPassword} // Secure text entry based on showPassword state
            right={
              Platform.OS !== 'web' ?
                <TextInput.Icon icon={showPassword ? 'eye' : 'eye-off'} onPress={() => setShowPassword(!showPassword)} /> :
                <TextInput.Icon
                  icon={() => (
                    showPassword ? (
                      <Image source={require('./show-icon1.png')} style={styles.hideIcon} />
                    ) : (
                      <Image source={require('./hide-icon2.png')} style={styles.hideIcon} />
                    )
                  )}
                  onPress={() => setShowPassword(!showPassword)}
                />
            }
            value={password}
            onChangeText={(text) => setPassword(text)}
          />
        </View>
        <TouchableOpacity style={styles.forgetPassword} onPress={() => forgetPassword(email)}>
          <Text style={styles.forgetPasswordLink}>Forgot Password?</Text>
        </TouchableOpacity>
        <LoginButton email={email} password={password} />
        <View style={styles.createAccountContainer}>
          <Text style={styles.title}>Don't have an account?</Text>
          <TouchableOpacity onPress={handleCreateAccount}>
            <Text style={styles.createAccountText}>Create an Account</Text>
          </TouchableOpacity>
        </View>
      </View>
      <ForgetPasswordModal
        modalVisible={modalVisible}
        closeModal={() => setModalVisible(false)}
      />
    </KeyboardAwareScrollView>
  );
};

// Get the window's width
const windowWidth = Dimensions.get('window').width;

// CSS STYLES
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 50,
    backgroundColor: '#fff',
    justifyContent: 'center',
    width: windowWidth,
    ...Platform.select({
      web: {
        justifyContent: 'flex-start',
      },
    }),
  },
  hideIcon: {
    width: 25,
    height: 25,
  },
  header: {
    alignItems: 'center'
  },
  logo: {
    width: 300,
    height: 300,
  },
  content: {
    alignItems: 'center',
  },
  title: {
    fontSize: 15,
    marginBottom: 30,
    color: 'black',
  },
  input: {
    width: '100%',
    borderRadius: 5,
    paddingHorizontal: 10,
    marginBottom: 20,
  },
  createAccountContainer: {
    flexDirection: 'row',
  },
  createAccountText: {
    color: '#CB6CE6',
    fontSize: 14,
    marginLeft: 5,
  },
  forgetPassword: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  forgetPasswordLink: {
    color: '#CB6CE6',
    fontSize: 14,
    marginTop: -30,
  },
  inputContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
});

export default LoginScreen;
