import React from "react";
import { StyleSheet, Pressable, Text } from "react-native";
import { updateUser } from '../../../controllers/update/update';
import { UserContext } from '../../../context/UserContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SignedInUserType } from '../../../types/UserContextType';

// INTERFACE PROPS TYPE
interface UpdateButtonProps {
  uid: string;
  user: any;
  disabled?: boolean;
}

// UPDATE BUTTON COMPONENT
const UpdateButton = ({ uid, user, disabled=false }: UpdateButtonProps) => {
  const { setNavigation, signedInUser } = React.useContext(UserContext);
  // NAVIGATION
  const handleButtonPress = async () => {
    updateUser(user, uid);
    if(Object.keys(signedInUser).length < 1) {
      await AsyncStorage.setItem('currentUserUid', uid);
    }
    setNavigation({ screen: 'CurrentUserProfile', props: { uid, email:'', user } });
  };

  return (
    <Pressable
      style={({ pressed }) => [
        styles.pressable,
        { backgroundColor: pressed ? 'lightblue' : disabled ? 'grey' : 'blue' }
      ]}
      onPress={handleButtonPress}
      disabled={disabled}
    >
      <Text style={styles.buttonText}>
        {Object.keys(signedInUser).length ? 'UPDATE' : 'CREATE'}
      </Text>
    </Pressable>
  );
};

// CSS STYLES
const styles = StyleSheet.create({
  pressable: {
    marginTop: 20,
    width: 'auto',
    borderRadius: 9,
    padding: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    fontSize: 16,
    color: 'white',
  },
});

export default UpdateButton;
