import { SignedInUserType } from './types/UserContextType';

const isProfileComplete = (user: SignedInUserType, isSingle: boolean): boolean => {
  // Check if user object is undefined or null
  if (!user || typeof user !== 'object') return false;
  // Check if user object is empty
  if (Object.keys(user).length === 0) return false;
  // Check if user is single
  if (isSingle) {
    // Check if displayName and photo fields have values
    if (user.displayName === '' || user.photo === '' ) {
      return false;
    }
  } else {
    // Check all fields except reqVerification and pUid
    for (const key in user) {
      if (key !== 'reqVerification' && key !== 'pUid' && key !== 'dob' && key !== 'refs') {
        if ((user as any)[key] === '') {
          return false;
        }
      }
    }
  }

  return true;
}

export const formatUsername = (username: string) => {
  return username.trim().toLowerCase().replace(/\s+/g, '-');
}

export const transformDate = (inputDate:string) => {
  const dateObj = new Date(inputDate);
  const month = dateObj?.toLocaleString('default', { month: 'short' });
  const day = dateObj?.getDate();
  const year = dateObj?.getFullYear();
  return `${month}, ${day}, ${year}`;
};

export const calculateAge = (birthdateString?: string): number => {
  // Check if birthdateString is undefined or null
  if (birthdateString === undefined || birthdateString === null) {
    return NaN;
  }

  // Split the birthdate string into components
  const [month, day, year] = birthdateString.split('/').map(Number);

  // Check if the components are valid
  if (isNaN(month) || isNaN(day) || isNaN(year)) {
    console.error("Invalid date format. Please provide the date in MM/DD/YYYY format.");
    return NaN;
  }

  // Create a new Date object using the components
  const birthdate: Date = new Date(year, month - 1, day);

  // Get current date
  const currentDate: Date = new Date();

  // Calculate age
  let age: number = currentDate.getFullYear() - birthdate.getFullYear();

  // Adjust age based on the month and day of birth
  if (currentDate.getMonth() < birthdate.getMonth() ||
      (currentDate.getMonth() === birthdate.getMonth() && currentDate.getDate() < birthdate.getDate())) {
    age--;
  }

  return age;
};




export const isValidDate = (dateString:string) => {
  // Regex pattern for MM/DD/YYYY format
  const pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  return pattern.test(dateString);
};

export default isProfileComplete;