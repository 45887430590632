import React from 'react';
import { Text, Share, StyleSheet, Platform, View } from 'react-native';
import { Button } from 'react-native-paper';

interface ShareableLinkProps {
  url: string;
}

const ShareableLink: React.FC<ShareableLinkProps> = ({ url }) => {
  const [URL, setURL] = React.useState(null as any);
  const handleShare = async () => {
    try {

      if (Platform.OS === 'web') {
        navigator.clipboard.writeText(url);
         setURL(url);
        }
       else {
        const result = await Share.share({
          message: `Join me on The Commitment App!${url}`,
        });

        if (result.action === Share.sharedAction) {
          if (result.activityType) {
            console.log('Shared with activity type:', result.activityType);
          } else {
            console.log('Shared');
          }
        } else if (result.action === Share.dismissedAction) {
          console.log('Dismissed');
        }

      }
    } catch (error) {
      console.error('Error sharing link:', error);
    }
  };

  // Extract the invite type based on URL params
  const queryParams = new URLSearchParams(url.split('?')[1]);
  const inviteType = queryParams.has('ref') ? 'friend' : 'partner';

  return (
    <>
    <Button style={styles.primaryButton} onPress={handleShare}>
      <Text style={styles.text}>Invite a {inviteType}</Text>
    </Button>
    {URL &&
     <View style={styles.cliboardMessage}>
      <Text>Copied to clipboard: </Text>
      <Text style={styles.clipboardUrl}>{URL}</Text>
     </View>
   }
    </>
  );
};

// CSS STYLES
const styles = StyleSheet.create({
  primaryButton: {
    marginTop: 20,
    marginBottom: 0,
    backgroundColor: '#5F45FF',
    borderRadius: 7,
    padding: 5,
    width: '100%',
  },
  text: {
    fontSize: 16,
    color: 'white',
  },
  cliboardMessage: {
   flexDirection: 'row',
   marginTop: 10,
  },
  clipboardUrl : {
   color:'blue'
  }
});

export default ShareableLink;
