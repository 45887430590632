import React, { useState, useContext } from "react";
import { StyleSheet, View, Text, Platform } from "react-native";
import { Button } from 'react-native-paper';
import { signInUser } from '../../../controllers/create/create';
import { UserContext } from '../../../context/UserContext';

// INTERFACE PROPS TYPE
interface Props {
  email?: string;
  password?: string;
  text?: string;
}

// LOGIN BUTTON COMPONENT
const LoginButton: React.FC<Props> = ({ email, password, text }) => {
  const [message, setMessage] = useState('');
  // NAVIGATION
  const { setNavigation } = useContext(UserContext);
  // HANDLE BUTTON PRESS
  const handleButtonPress = async () => {
    try {
      if (email && password) {
        const currentUserUid = await signInUser(email, password);
        // CHECK IF USER IS VERIFIED
        if(currentUserUid){
          // SEND USER TO CURRENT USER PROFILE SCREEN
          setNavigation({ screen: 'CurrentUserProfile', props: { uid: currentUserUid, email } });
        } else {
          // HANDLE ERROR
          setMessage('Email or password is incorrect.');
          throw new Error('No user found.');
        }

      } else {
        setMessage('Email and password are required.');
        throw new Error('Email and password are required.');
      }
    } catch (error) {
      // Handle login error
      console.log('Login error:', error);
    }
  };

  return (
    <View>
      <Text style={styles.text}>{message}</Text>
    <Button
      style={styles.primaryButton}
      mode="contained"
      onPress={handleButtonPress}
    >
      {text ? text : 'Login'}
    </Button>
    </View>
  );
};

// CSS STYLES
const styles = StyleSheet.create({
  primaryButton: {
    marginTop: 20,
    marginBottom: 20,
    width: 300,
    backgroundColor: '#CB6CE6',
    borderRadius: 9,
    padding: 5,
    ...Platform.select({
      android: {
        marginTop: -15,
      },
    }),
  },
  text: {
    marginTop: 20,
    fontSize: 20,
    color: 'red',
  },
});

export default LoginButton;

