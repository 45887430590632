import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import NavLink from '../NavLink/NavLink';

// INTERFACE
interface BottomNavProps {
  uid?: string;
  request?: string[];
};

const BottomNav = ({uid, request}:BottomNavProps) => {
  return (
      <View style={styles.navContainer} testID="nav-container">
        <NavLink to="CurrentUserProfile" uid={uid} iconName="person"/>
        <NavLink to="SearchScreen" iconName="search" />
        <NavLink to="NotificationsScreen" request={request} iconName="notifications" />
        <NavLink to="SettingsScreen" iconName="settings" />
      </View>
  );
};

const styles = StyleSheet.create({
  navContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 10,
    width: '85%',
    height: 60,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: -10 }, // change this for more shadow
    shadowOpacity: 0.2,
    shadowRadius: 10,
    bottom: 0,
    // @ts-ignore
    position: 'absolute',
    // platform specific styles
    ...Platform.select({
      web:{
        marginBottom: 30,
        position: 'fixed',
      },
      ios: {
        shadowColor: 'black',
        shadowOffset: { width: 0, height: -5 }, // change this for more shadow
        shadowOpacity: 0.2,
        shadowRadius: 10,
        marginBottom: 40,
      },
      android: {
        elevation: 20,
        marginBottom: 30,
      },
    }),
  },
});

export default BottomNav;
