import React, { useContext } from 'react';
import { Modal, View, Image, TouchableOpacity, Text, StyleSheet, Dimensions, Platform, FlatList } from 'react-native';
import { UserContext } from '../../../context/UserContext';
import { User } from '../../../types/UserContextType';

interface FriendsModalProps {
  visible: boolean;
  onClose: () => void;
  friends: User[];
  currentUser?: User;
}

const FriendsModal: React.FC<FriendsModalProps> = ({ visible, onClose, friends, currentUser }) => {
  const { signedInUser, setNavigation, setUserID } = useContext(UserContext);

  const handleFriendClick = (uid: string) => {
    setNavigation({ screen: 'UserProfile' });
    setUserID(uid);
    onClose();
  };

  const renderItem = ({ item }: { item: User }) => (
    <TouchableOpacity onPress={() => handleFriendClick(item.uid)}>
      <View style={styles.friendContainer}>
        <Image
          source={{ uri: item.photo }}
          style={styles.modalFriend}
        />
        <View style={styles.descriptionContainer}>
          <Text style={styles.relationship}>{currentUser?.refs?.includes(item.uid) ? 'Friend' : 'Partner'}</Text>
          <Text style={styles.displayName}>{item.displayName}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={onClose}
    >
      <View style={styles.modal}>
        <View style={styles.modalContent}>
          <Text style={styles.close} onPress={onClose}>×</Text>
          <Text style={styles.title}>Friends + Partners </Text>
          {
          Platform.OS !== 'web' ?
          <FlatList
            data={friends}
            renderItem={renderItem}
            numColumns={3}
            keyExtractor={(item) => item.uid}
            contentContainerStyle={styles.flatListContent}
          />:
          <View style={styles.webModalContainer}>
            {friends.map((friend) => (
              <TouchableOpacity key={friend.uid} onPress={() => handleFriendClick(friend.uid)}>
                <View style={styles.friendContainer}>
                  <Image
                    source={{ uri: friend.photo }}
                    style={styles.modalFriend}
                  />
                  <View style={styles.descriptionContainer}>

                    <Text style={styles.relationship}>{currentUser?.refs?.includes(friend.uid) ? 'Friend' : 'Partner'}</Text>
                    <Text style={styles.displayName}>{friend.displayName}</Text>
                  </View>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        }
        </View>
      </View>
    </Modal>
  );
};

export default FriendsModal;

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 10,
    fontSize: 20,
    fontWeight: 'bold',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    width: width * 0.8,
    maxHeight: '50%',
  },
  webModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  flatListContent: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  friendContainer: {
    alignItems: 'center',
    marginBottom: 30,
    marginRight: 20,
  },
  modalFriend: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  descriptionContainer: {
    marginTop: 5,
    alignItems: 'center',
  },
  displayName: {
    fontSize: 16,
    fontWeight: '400',
  },
  relationship: {
    fontSize: 14,
    color: 'blue',
  },
});
