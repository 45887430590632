import React from 'react';
import { View, Image, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { User } from '../../types/UserContextType';

interface FriendsProps {
  friends: User[];
  openModal: () => void;
}

const Friends = ({ friends, openModal }: FriendsProps) => {
  return (
    <View style={styles.friendCircles}>
      {friends?.map((friend) => (
        <TouchableOpacity key={friend.uid} onPress={openModal}>
          <Image source={{ uri: friend.photo }} style={styles.friendCircle}/>
        </TouchableOpacity>
      ))}
      {
        friends?.length <= 4 ? null : (
          <TouchableOpacity style={styles.overflowCircle} onPress={openModal}>
            <Text>{`+${friends.length - 4}`}</Text>
          </TouchableOpacity>
        )
      }
    </View>
  );
}

export default Friends;

const styles = StyleSheet.create({
  friendCircles: {
    display: 'flex',
    flexDirection: 'row',
    height: 25,
    width: 25,
  },
  friendCircle: {
    width: 30,
    height: 30,
    borderRadius: 25,
    marginRight: -10,

  },
  overflowCircle: {
    backgroundColor: '#ccc',
    width: 30,
    height: 30,
    borderRadius: 25,
    marginLeft: -20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
