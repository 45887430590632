import { createContext } from 'react';
import { User, UserContextType, navigationType } from '../types/UserContextType';

// USER CONTEXT
export const UserContext = createContext<UserContextType>({
  users: [{
    uid: '',
    displayName: '',
    email: '',
    dob: '',
    pUid: [],
    photo: '',
    partners_name: '',
    partners_photo: '',
    relationship_title: '',
    reqVerification: [],
    start_of_relationship: '',
    isSingle: false,
    refs:[],
  }],
  signedInUser: {
    uid: '',
    displayName: '',
    email: '',
    dob: '',
    pUid: [],
    photo: '',
    partners_name: '',
    partners_photo: '',
    relationship_title: '',
    reqVerification: [],
    start_of_relationship: '',
    isSingle: false,
    refs:[],
  },
  setSignedInUser: function (user: User): void {
    throw new Error('Function not implemented.');
  },
  setNavigation: function ({}: navigationType): void {
    throw new Error('Function not implemented.');
  },
  navigation: { screen: '', props: { uid: '', email: '', user: {
    uid: '',
    displayName: '',
    email: '',
    dob: '',
    pUid: [],
    photo: '',
    partners_name: '',
    partners_photo: '',
    relationship_title: '',
    reqVerification: [],
    start_of_relationship: '',
    isSingle: false,
    refs:[],
  } } },
  currentUserID: '',
  setCurrentUserID: function (id: string): void {
    throw new Error('Function not implemented.');
  },
  userID: '',
  setUserID: function (id: string): void {
    throw new Error('Function not implemented.');
  },
  isSingle: false, // Add isSingle property with default value
  setIsSingle: function(isSingle: boolean): void {
    throw new Error('Function not implemented.');
  },
  setQueryParams: function ({}): void {
    throw new Error('Function not implemented.');
  },
  queryParams: {},
  url: '',
  setUrl: function (url: string): void {
    throw new Error('Function not implemented.');
  },
  refs: [], // Add refs property with default value
});
