import React, { useState, useEffect } from 'react';
import { View, TextInput, Button, Image, StyleSheet, Text } from 'react-native';
import ClickableUploadText from '../../ClickableUploadText/ClickableUploadText';

interface Props {
	displayName: string;
	setName: (value: string) => void
	setPhoto: (value: string) => void
	image1: { uri: string };
	uid: string;

}

function SingleSignupForm({ displayName, setName, setPhoto, image1, uid }: Props) {
	return (
		<View style={styles.container}>
			<TextInput
				style={styles.input}
				placeholder="Your Name"
				value={displayName}
				onChangeText={setName}
			/>
			<View style={styles.uploadPhotoContainer}>
				<View style={styles.imageContainer}>
					<Image style={styles.profilePhoto} source={image1} />
					<ClickableUploadText setImage={setPhoto} userUid={uid} fileName={!displayName ? 'displayName' : displayName} partner={false}/>
				</View>
			</View>
		</View>
	);
}
export default SingleSignupForm

const styles = StyleSheet.create({
	container: {
		marginBottom: 50,
	},

	header: {
		marginTop: 50,
		marginBottom: 70,
	},
	imageContainer: {
		flexDirection: 'column',
		alignItems: 'center',
		padding: 6,
		width: '100%',
	},
	profilePhoto: {
		borderRadius: 5,
		height: 150,
		width: 160,
	},
	input: {
		width: '100%',
		height: 50,
		borderColor: '#ccc',
		borderWidth: 1,
		borderRadius: 5,
		paddingHorizontal: 10,
		marginBottom: 50,
	},
	uploadPhotoContainer: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		marginBottom: 20,
	},
	text: {
		fontSize: 30,
		textAlign: 'center',
	},
	footNote: {
		color: 'red',
		textAlign: 'center',
		marginTop: 10,
	},
});