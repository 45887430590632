import React, { FC, useContext } from 'react';
import { View, Text, Image, StyleSheet, Modal } from 'react-native';
import BackHomeButton from '../../Buttons/BackHomeButton/BackHomeButton';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { UserContext } from '../../../context/UserContext';

interface SuccessModalProps {
  visible: boolean;
  onClose: () => void;
  icon: string;
  header: string;
  message: string;
}


const SuccessModal: FC<SuccessModalProps> = ({ visible, onClose, icon, header, message }) => {
  const { setNavigation } = useContext(UserContext);
  const handleButtonPress = () => {
    setNavigation({ screen: 'HomeScreen' });
    onClose();
  };

  return (
    <Modal animationType="fade" transparent visible={visible} onRequestClose={onClose}>
      <View style={styles.modalContainer}>
        <View style={styles.content}>
          <View style={styles.iconContainer}>
            <MaterialCommunityIcons name={"check-circle"} size={60} color="blue" />
          </View>
          <Text style={styles.header}>{header}</Text>
          <Text style={styles.message}>{message}</Text>
          <BackHomeButton text="Back to Home" press={handleButtonPress} />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    padding: 10,
  },
  content: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
  },
  iconContainer: {
    marginBottom: 15,
  },
  header: {
    fontSize: 24,
  },
  message: {
    fontSize: 18,
    marginBottom: 15,
    textAlign: 'center',
  },
  closeButton: {
    backgroundColor: '#007AFF',
    padding: 10,
    borderRadius: 8,
    marginTop: 10,
  },
  closeButtonText: {
    color: '#fff',
    fontSize: 16,
  },
});

export default SuccessModal;
